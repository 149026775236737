import { toast } from 'react-toastify';
import {
	apiGetFullProjectByProjectNumber,
	apiGetImage,
	IImageOutputS3Backend,
	IFullProjectBackend,
	IImageOutputBackend,
} from './apiService';

interface IGetProjectBackendProps {
	formik: any;
	setFullProject: (v: IFullProjectBackend | null) => void;
	setSelectedProjecId: (n: number) => void;
	setSelectedClientId: (n: number) => void;
	setScopeCount: (n: number) => void;
	setTeamSize: (n: number) => void;
	setMaterialCount: (n: number) => void;
	setIsSubmitting: (b: boolean) => void;
	setImagesIdFullProject: (b: IImageOutputBackend[]) => void;
}

export const getProjectBackend = async ({
	formik,
	setFullProject,
	setSelectedProjecId,
	setSelectedClientId,
	setScopeCount,
	setTeamSize,
	setMaterialCount,
	setIsSubmitting,
	setImagesIdFullProject,
}: IGetProjectBackendProps): Promise<void> => {
	setIsSubmitting(true); // Ativa o spinner
	try {
		if (formik.values.projectNumber !== '') {
			const imageOutputs: IImageOutputS3Backend[] = [];
			const fullProject = await apiGetFullProjectByProjectNumber(
				Number(formik.values.projectNumber)
			);
			setImagesIdFullProject(fullProject.images);

			// Obtendo as imagens
			try {
				for (const image of fullProject.images) {
					// Garantia de erro se falhar
					const imageOut = await apiGetImage(image.id);
					imageOutputs.push(imageOut);
				}
			} catch (error) {
				toast.error('ERROR: falha ao carregar imagens.');
				throw new Error(
					'Falha ao carregar imagens: ' + (error as Error).message
				);
			}

			setFullProject(fullProject);
			setSelectedProjecId(fullProject.id);
			formik.setFieldValue('projectName', fullProject.project_name);
			formik.setFieldValue('clientId', fullProject.client.id);
			setSelectedClientId(fullProject.client.id);
			formik.setFieldValue('userId', fullProject.user.id);
			formik.setFieldValue('platformId', fullProject.platform.id);
			formik.setFieldValue('purchaseOrder', fullProject.purchase_order);
			formik.setFieldValue('proposal', fullProject.quote_number);
			formik.setFieldValue(
				'projectClassificationId',
				fullProject.project_classification.id
			);
			formik.setFieldValue(
				'contractCategoryId',
				fullProject.contract_category.id
			);
			setScopeCount(fullProject.project_scope_ids.length);
			formik.setFieldValue('scopeCount', fullProject.project_scope_ids.length);

			formik.setFieldValue('materialCount', fullProject.materials.length);
			formik.setFieldValue(
				'projectScopeIds',
				fullProject.project_scope_ids.map(item => ({
					projectScopeId: item.project_scope_id,
					projectScopeDetaisId: item.project_scope_detais_id,
				}))
			);

			formik.setFieldValue('scopeObservations', fullProject.scope_observations);
			formik.setFieldValue('teamQuantity', fullProject.team_quantity);
			formik.setFieldValue('teamSize', fullProject.team_size);
			setTeamSize(fullProject.team_size);
			formik.setFieldValue('shiftId', fullProject.shift.id);
			formik.setFieldValue(
				'customerExpectation',
				fullProject.customer_expectation
			);
			formik.setFieldValue(
				'expectedEmbarkationDate',
				fullProject.expected_embarkation_date
			);
			formik.setFieldValue('embarkationPeriod', fullProject.embarkation_period);
			formik.setFieldValue(
				'embarkationRegime',
				fullProject.embarkation_regime.id
			);
			formik.setFieldValue('numberOfBoarding', fullProject.number_of_boarding);
			formik.setFieldValue('observation', fullProject.observation);
			formik.setFieldValue('boardingStatus', fullProject.status.id);
			formik.setFieldValue(
				'materialSelections',
				fullProject.materials.map(material => ({
					materialId: material.material_id,
					quantity: material.quantity,
				}))
			);
			formik.setFieldValue(
				'saleOperationRoleIds',
				fullProject.sale_operation_roles.map(sor => sor.sale_operation_role_id)
			);
			// Mapeamento seguro das imagens

			formik.setFieldValue(
				'images',
				imageOutputs.map(item => ({
					name: item.image.image_url, // Extraindo apenas o nome da URL
					contentBase64: item.content_base_64,
				}))
			);

			setMaterialCount(fullProject.materials.length);
		}
	} catch (error) {
		toast.error('ERROR: falha ao carregar os dados do projeto.');
	} finally {
		setIsSubmitting(false); // Desativa o spinner
	}
};
