import React, { useState, useEffect } from 'react';
import {
	Button,
	Box,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	SelectChangeEvent,
	Grid,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import {
	apiGetAllPlatformsClients,
	apiGetAllProjectClassification,
	apiGetAllProjectNumber,
	apiGetAllProjectScope,
	apiGetAllUsers,
	apiGetWorkerBoardingByCriteria,
	IClientBackEnd,
	IPlatformBackEnd,
	IProjectClassificationBackEnd,
	IProjectNumberBackEnd,
	IProjectScopeBackEnd,
	IUserBackEnd,
	IWorkerHistogram,
	apiGetWorkerHistogram,
	IOperationRoleBackEnd,
	ISkill,
	apiGetAllOperationRoles,
	apiGetAllSkills,
	IWorkerNameBackEnd,
	apiGetAllWorkerNames,
	IProjectNumberDrakeBackEnd,
	apiGetAllProjectNumberDrake,
} from '../../../services/apiService';
import { removeDuplicatesByIdUtil } from '../../../utils/clientsBackEnd';
import { toast } from 'react-toastify';
import { getMonthBoundaryDate } from '../../../utils/getMonthBoundaryDate';
import axios from 'axios';

interface FiltersProps {
	setWorkerHistogram: React.Dispatch<
		React.SetStateAction<IWorkerHistogram | undefined>
	>;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
	startDate: string;
	setStartDate: React.Dispatch<React.SetStateAction<string>>;
	endDate: string;
	setEndDate: React.Dispatch<React.SetStateAction<string>>;
	filterScheduled: boolean;
	setFilterScheduled: React.Dispatch<React.SetStateAction<boolean>>;
}

const Filters: React.FC<FiltersProps> = ({
	setWorkerHistogram,
	setIsLoading,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	filterScheduled,
	setFilterScheduled,
}) => {
	const [dropdownClient, setDropdownClient] = useState<IClientBackEnd[]>([]);
	const [selectedClientId, setSelectedClientId] = useState<number | ''>('');
	const [dropdownPlatform, setDropdownPlatform] = useState<IPlatformBackEnd[]>(
		[]
	);
	const [filteredPlatforms, setFilteredPlatforms] = useState<
		IPlatformBackEnd[]
	>([]);
	const [selectedPlatformId, setSelectedPlatformId] = useState<number | ''>('');
	const [dropdownProjectNumber, setDropdownProjectNumber] = useState<
		IProjectNumberBackEnd[]
	>([]);
	const [dropdownProjectNumberDrake, setDropdownProjectNumberDrake] =
		useState<IProjectNumberDrakeBackEnd>({
			project_numbers: [],
		});
	const [selectedProjectNumber, setSelectedProjectNumber] = useState<
		number | ''
	>('');
	const [dropdownProjectScope, setDropdownProjectScope] = useState<
		IProjectScopeBackEnd[]
	>([]);
	const [selectedProjectScopeId, setSelectedProjectScopeId] = useState<
		number | ''
	>('');
	const [dropdownProjectClassification, setDropdownProjectClassification] =
		useState<IProjectClassificationBackEnd[]>([]);
	const [selectedProjectClassificationId, setSelectedProjectClassificationId] =
		useState<number | ''>('');
	const [dropdownUser, setDropdownUser] = useState<IUserBackEnd[]>([]);
	const [selectedUserId, setSelectedUserId] = useState<number | ''>('');
	const [dropdownOperationRole, setDropdownOperationRole] = useState<
		IOperationRoleBackEnd[]
	>([]);
	const [dropdownWorkerNames, setDropdownWorkerNames] = useState<
		IWorkerNameBackEnd[]
	>([]);
	const [selectedOperationRoleIds, setSelectedOperationRoleIds] = useState<
		number[]
	>([]);
	const [selectedWorkerIds, setSelectedWorkerIds] = useState<number[]>([]);
	const [dropdownSkill, setDropdownSkill] = useState<ISkill[]>([]);
	const [selectedSkills, setSelectedSkills] = useState<ISkill[]>([]);

	useEffect(() => {
		const fetchDropdownData = async () => {
			try {
				const backEndAllPlatformsClients = await apiGetAllPlatformsClients();
				const clients = backEndAllPlatformsClients.map(obj => obj.client);
				const clientsUnique = removeDuplicatesByIdUtil(clients);
				setDropdownClient(clientsUnique);
				setDropdownPlatform(backEndAllPlatformsClients);
				setFilteredPlatforms(backEndAllPlatformsClients);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados de clientes e plataformas.'
				);
			}
			try {
				const backEndAllProjectNumber = await apiGetAllProjectNumber();
				setDropdownProjectNumber(backEndAllProjectNumber);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados dos números dos projetos.'
				);
			}
			try {
				const backEndAllProjectNumber = await apiGetAllProjectNumberDrake();
				setDropdownProjectNumberDrake(backEndAllProjectNumber);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados dos números dos projetos Drake.'
				);
			}
			try {
				const backEndAllUser = await apiGetAllUsers();
				setDropdownUser(backEndAllUser);
			} catch (error) {
				toast.error('ERROR: falha ao carregar os dados de coordenadores.');
			}
			try {
				const backEndAllProjectClassification =
					await apiGetAllProjectClassification();
				setDropdownProjectClassification(backEndAllProjectClassification);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados de classificação de projeto.'
				);
			}
			try {
				const backEndAllProjectScope = await apiGetAllProjectScope();
				setDropdownProjectScope(backEndAllProjectScope);
			} catch (error) {
				toast.error('ERROR: falha ao carregar os dados de escopo de projeto.');
			}
			try {
				const backEndAllOperationRole = await apiGetAllOperationRoles();
				setDropdownOperationRole(backEndAllOperationRole);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados de funções de operação.'
				);
			}
			try {
				const backEndAllSkill = await apiGetAllSkills();
				setDropdownSkill(backEndAllSkill);
			} catch (error) {
				toast.error('ERROR: falha ao carregar os dados de habilidades.');
			}
			try {
				const names = await apiGetAllWorkerNames();
				const sortedNames = names.sort((a, b) =>
					a.worker_name.localeCompare(b.worker_name)
				);
				setDropdownWorkerNames(sortedNames);
			} catch (error) {
				toast.error('Erro ao carregar trabalhadores.');
			}
		};

		fetchDropdownData();
	}, []);

	useEffect(() => {
		if (selectedClientId) {
			const filtered = dropdownPlatform.filter(
				platform => platform.client.id === selectedClientId
			);
			setFilteredPlatforms(filtered);
		}
	}, [selectedClientId]);

	const handleFilterSubmit = () => {
		const filters = {
			startDate,
			endDate,
			selectedClientId,
			selectedPlatformId,
			selectedProjectNumber,
			selectedProjectScopeId,
			selectedProjectClassificationId,
			selectedUserId,
			selectedOperationRoleIds, // Atualizado para suportar múltiplos IDs
			selectedSkills,
		};
		console.log('🚀  filters:', filters);
		const fetchWorkerHistogram = async () => {
			try {
				const workerHistogram = await apiGetWorkerHistogram(
					filterScheduled,
					startDate,
					endDate,
					selectedClientId,
					selectedPlatformId,
					selectedProjectNumber,
					selectedProjectScopeId,
					selectedProjectClassificationId,
					selectedUserId,
					selectedOperationRoleIds,
					selectedWorkerIds,
					selectedSkills
				);
				workerHistogram.workers.sort((a, b) =>
					a.worker_name.trim().localeCompare(b.worker_name.trim())
				);
				setWorkerHistogram(workerHistogram);
			} catch (error) {
				setWorkerHistogram(undefined);
				if (axios.isAxiosError(error)) {
					if (error.response?.status !== 404) {
						toast.error('ERROR: falha ao carregar dados.');
					} else {
						toast.warning('WARNING: nenhum dado encontrado.');
					}
				} else {
					toast.error('ERROR: falha ao carregar dados.');
				}
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500); // Espera de 1 segundo fixa além do tempo de carregamento dos dados
			}
		};
		if (startDate === '' || endDate === '') {
			toast.warning('As datas devem estar preenchidas.');
		} else {
			setIsLoading(true);
			fetchWorkerHistogram();
		}
	};

	const handleChangeSelectedSkills = (event: SelectChangeEvent<number[]>) => {
		const {
			target: { value },
		} = event;

		const selectedIds =
			typeof value === 'string' ? value.split(',').map(Number) : value;
		const selectedSkills = dropdownSkill.filter(skill =>
			selectedIds.includes(skill.id)
		);

		setSelectedSkills(selectedSkills);
	};

	const handleChangeSelectedOperationRoles = (
		event: SelectChangeEvent<number[]>
	) => {
		const {
			target: { value },
		} = event;

		const selectedIds =
			typeof value === 'string' ? value.split(',').map(Number) : value;

		setSelectedOperationRoleIds(selectedIds);
	};

	const handleChangeSelectedWorkers = (event: SelectChangeEvent<number[]>) => {
		const {
			target: { value },
		} = event;

		const selectedIds =
			typeof value === 'string' ? value.split(',').map(Number) : value;

		setSelectedWorkerIds(selectedIds);
	};

	const handleClear = () => {
		setSelectedPlatformId('');
		setSelectedProjectNumber('');
		setSelectedClientId('');
		setSelectedProjectScopeId('');
		setSelectedProjectClassificationId('');
		setSelectedUserId('');
		setStartDate('');
		setEndDate(''); // Esta linha deve limpar o endDate
		setSelectedSkills([]);
		setSelectedOperationRoleIds([]);
		setSelectedWorkerIds([]);
    setFilteredPlatforms(dropdownPlatform);
	};

	return (
		<Box display="flex" flexDirection="column" gap="20px">
			<Box display="flex" flexWrap="wrap" gap="20px">
				<TextField
					label="Data início"
					type="date"
					value={startDate}
					onChange={e => setStartDate(e.target.value)}
					InputLabelProps={{
						shrink: true,
					}}
				/>
				<TextField
					label="Data fim"
					type="date"
					value={endDate}
					onChange={e => setEndDate(e.target.value)}
					InputLabelProps={{
						shrink: true,
					}}
				/>
				<FormControl style={{ minWidth: '150px' }}>
					<InputLabel>Coordenador</InputLabel>
					<Select
						label="Coordenador"
						value={selectedUserId}
						onChange={e => setSelectedUserId(+e.target.value)}
					>
						{dropdownUser
							.sort((a, b) => a.username.localeCompare(b.username))
							.filter(user => user.coordinator)
							.map(coordinator => (
								<MenuItem key={coordinator.id} value={coordinator.id}>
									{coordinator.username}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				<FormControl style={{ minWidth: '150px' }}>
					<InputLabel>Cliente</InputLabel>
					<Select
						label="Cliente"
						value={selectedClientId}
						onChange={e => {
							setSelectedClientId(+e.target.value);
							setSelectedPlatformId('');
						}}
					>
						{dropdownClient
							.sort((a, b) => a.client_name.localeCompare(b.client_name))
							.map(client => (
								<MenuItem key={client.id} value={client.id}>
									{client.client_name}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				<FormControl style={{ minWidth: '150px' }}>
					<InputLabel>Rigs</InputLabel>
					<Select
						label="Rigs"
						value={selectedPlatformId}
						onChange={e => setSelectedPlatformId(+e.target.value)}
					>
						{filteredPlatforms
							.sort((a, b) => a.platform_name.localeCompare(b.platform_name))
							.map(platform => (
								<MenuItem key={platform.id} value={platform.id}>
									{platform.platform_name}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				<FormControl style={{ minWidth: '150px' }}>
					<InputLabel>WO</InputLabel>
					<Select
						label="WO"
						value={selectedProjectNumber}
						onChange={e => setSelectedProjectNumber(+e.target.value)}
					>
						{dropdownProjectNumberDrake.project_numbers.map(
							(projectNumber, index) => (
								<MenuItem key={index} value={projectNumber}>
									{projectNumber}
								</MenuItem>
							)
						)}
					</Select>
				</FormControl>
				<FormControl style={{ minWidth: '150px' }}>
					<InputLabel>Escopo</InputLabel>
					<Select
						label="Escopo"
						value={selectedProjectScopeId}
						onChange={e => setSelectedProjectScopeId(+e.target.value)}
					>
						{dropdownProjectScope
							.sort((a, b) => a.sow.localeCompare(b.sow))
							.map(project_scope => (
								<MenuItem key={project_scope.id} value={project_scope.id}>
									{project_scope.sow}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				<FormControl style={{ minWidth: '150px' }}>
					<InputLabel>Tipo</InputLabel>
					<Select
						label="Tipo"
						value={selectedProjectClassificationId}
						onChange={e => setSelectedProjectClassificationId(+e.target.value)}
					>
						{dropdownProjectClassification
							.sort((a, b) => a.description.localeCompare(b.description))
							.map(project_classification => (
								<MenuItem
									key={project_classification.id}
									value={project_classification.id}
								>
									{project_classification.description}
								</MenuItem>
							))}
					</Select>
				</FormControl>
			</Box>
			<Box display="flex" flexWrap="wrap" gap="20px" alignItems="center">
				<FormControl style={{ minWidth: '515px' }}>
					<InputLabel>Funções</InputLabel>
					<Select
						label="Funções"
						multiple
						value={selectedOperationRoleIds}
						onChange={handleChangeSelectedOperationRoles}
						renderValue={selectedIds => {
							const selectedDescriptions = dropdownOperationRole
								.filter(role => (selectedIds as number[]).includes(role.id))
								.map(role => role.description);
							return selectedDescriptions.join(', ');
						}}
					>
						{dropdownOperationRole
							.sort((a, b) =>
								a.description.trim().localeCompare(b.description.trim())
							)
							.map(operationRole => (
								<MenuItem key={operationRole.id} value={operationRole.id}>
									{operationRole.description}
								</MenuItem>
							))}
					</Select>
				</FormControl>

				<FormControl style={{ minWidth: '150px' }}>
					<InputLabel>Habilidades</InputLabel>
					<Select
						label="Habilidades"
						multiple
						value={selectedSkills.map(skill => skill.id)}
						onChange={handleChangeSelectedSkills}
						renderValue={selectedIds => {
							const selectedDescriptions = dropdownSkill
								.filter(skill => (selectedIds as number[]).includes(skill.id))
								.map(skill => skill.description);
							return selectedDescriptions.join(', ');
						}}
					>
						{dropdownSkill
							.sort((a, b) => a.description.localeCompare(b.description))
							.map(skill => (
								<MenuItem key={skill.id} value={skill.id}>
									{skill.description}
								</MenuItem>
							))}
					</Select>
				</FormControl>

				<FormControl style={{ minWidth: '515px' }}>
					<InputLabel>Trabalhadores</InputLabel>
					<Select
						label="Trabalhadores"
						multiple
						value={selectedWorkerIds}
						onChange={handleChangeSelectedWorkers}
						renderValue={selectedIds => {
							const selectedDescriptions = dropdownWorkerNames
								.filter(worker => (selectedIds as number[]).includes(worker.id))
								.map(worker => worker.worker_name);
							return selectedDescriptions.join(', ');
						}}
					>
						{dropdownWorkerNames
							.sort((a, b) =>
								a.worker_name.trim().localeCompare(b.worker_name.trim())
							)
							.map(worker => (
								<MenuItem key={worker.id} value={worker.id}>
									{worker.worker_name}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				<Box display="flex" flexWrap="wrap" gap="20px">
					<Button
						variant="contained"
						onClick={handleFilterSubmit}
						style={{ height: '56px' }}
					>
						Filtrar
					</Button>
					<Button
						variant="contained"
						onClick={handleClear}
						style={{
							height: '56px',
							background: '#e5e5e5',
							color: 'black',
						}}
					>
						Limpar
					</Button>
					<FormControlLabel
						control={
							<Checkbox
								checked={filterScheduled}
								onChange={e => setFilterScheduled(e.target.checked)}
								name="filterUnscheduled"
								color="primary"
							/>
						}
						label="Programados"
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default Filters;
