import { jsPDF } from 'jspdf';
import { IFullProjectBackend } from './apiService';

// Função para processar e limpar o Base64
const processBase64Image = (base64: string): string => {
	// Verifica e remove o prefixo 'data:image/jpeg;base64,' ou similar
	const base64Prefix = /^data:image\/(jpeg|png);base64,/;
	if (base64Prefix.test(base64)) {
		return base64.replace(base64Prefix, '');
	}
	return base64; // Retorna sem alteração caso não tenha o prefixo
};

// Função para adicionar um campo ao PDF
const addFieldToPDF = (
	doc: jsPDF,
	key: string,
	value: any,
	pageWidth: number,
	y: number,
	lineHeight: number,
	marginX: number,
	pageHeight: number
): number => {
	const displayValue =
		typeof value === 'object' ? JSON.stringify(value, null, 2) : value;

	doc.setDrawColor(0);
	doc.setLineWidth(0.5);
	doc.line(marginX, y, pageWidth - marginX, y);
	y += 8;

	const lines: string[] = doc.splitTextToSize(`${key}: ${displayValue}`, 190);

	lines.forEach((line: string) => {
		doc.setFontSize(13);
		doc.text(line, marginX, y);
		y += lineHeight;

		if (y > pageHeight) {
			doc.addPage();
			y = 10;
		}
	});

	y += 5;
	return y;
};

export const handleDownloadPDF = async (
	formValues: any,
	fullProject: IFullProjectBackend | null
) => {
	const doc = new jsPDF({
		orientation: 'portrait',
		unit: 'mm',
		format: 'a4',
	});

	// Largura da página
	const pageWidth = doc.internal.pageSize.getWidth();

	const imagePath = './qualitech.jpeg';

	const loadImageAsBase64 = (path: string): Promise<string> =>
		new Promise((resolve, reject) => {
			const img = new Image();
			img.src = path;
			img.onload = () => {
				const canvas = document.createElement('canvas');
				canvas.width = img.width;
				canvas.height = img.height;
				const ctx = canvas.getContext('2d');
				if (ctx) {
					ctx.drawImage(img, 0, 0);
					resolve(canvas.toDataURL('image/jpeg'));
				} else {
					reject(new Error('Não foi possível obter o contexto da imagem.'));
				}
			};
			img.onerror = () => reject(new Error('Erro ao carregar a imagem.'));
		});

	try {
		const base64Image = await loadImageAsBase64(imagePath);

		// Imagem no canto superior direito
		const imageWidth = 42;
		const imageHeight = 42;
		const marginRight = 10;
		const imageX = pageWidth - imageWidth - marginRight;
		const imageY = 10;

		doc.addImage(base64Image, 'JPEG', imageX, imageY, imageWidth, imageHeight);
	} catch (error) {
		console.error('Erro ao carregar a imagem:', error);
	}

	// Data
	const today = new Date();
	const formattedDate = `${today.getDate().toString().padStart(2, '0')}/${(
		today.getMonth() + 1
	)
		.toString()
		.padStart(2, '0')}/${today.getFullYear()}`;

	// Escolhemos y=55 para imprimir ambos na mesma linha
	const lineY = 55;

	// 1) Título maior à esquerda
	doc.setFont('helvetica', 'bold');
	doc.setFontSize(18);
	doc.text(`Projeto cadastrado: WO ${fullProject?.project_number}`, 10, lineY);

	// 2) Texto "Documento gerado..." à direita, em itálico e fonte menor
	doc.setFont('helvetica', 'italic');
	doc.setFontSize(12);
	const dateText = `Documento gerado: ${formattedDate}`;
	const dateTextWidth = doc.getTextWidth(dateText);
	const dateTextX = pageWidth - dateTextWidth - 10;
	doc.text(dateText, dateTextX, lineY);

	// >>> Retorna a fonte para normal para evitar que todo o texto fique em itálico
	doc.setFont('helvetica', 'normal');

	// Agora seguimos com o corpo do PDF
	let y = 65; // Início dos campos
	const pageHeight = 280;
	const lineHeight = 8;
	const marginX = 10;

	y = addFieldToPDF(
		doc,
		'Número do projeto',
		fullProject?.project_number,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'PO',
		fullProject?.purchase_order,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Nome do projeto',
		fullProject?.project_name,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Proposta',
		fullProject?.quote_number,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Cliente',
		fullProject?.client.client_name,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Plataforma',
		fullProject?.platform.platform_name,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Categoria do contrato',
		fullProject?.contract_category.description,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Coordenador',
		fullProject?.user.username,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Quantidade de escopos',
		fullProject?.project_scope_ids.length,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Escopo do projeto',
		'\n - ' +
			fullProject?.project_scope_ids
				.map(
					scope =>
						`${scope.project_scope.sow} / ${scope.project_scope_detais.sow_detais} (DETAIS)`
				)
				.join('\n - '),
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Quantidade de materiais',
		fullProject?.materials.length,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Materiais',
		'\n - ' +
			fullProject?.materials
				.map(
					material => `${material.material.description} (${material.quantity})`
				)
				.join('\n - '),
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Classificação do projeto',
		fullProject?.project_classification.description,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Observações de escopo',
		fullProject?.scope_observations,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Quantidade de equipes',
		fullProject?.team_quantity,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Turno',
		fullProject?.shift.description,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Número total de pessoas',
		fullProject?.team_size,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Perfis dos funcionários',
		'\n - ' +
			fullProject?.sale_operation_roles
				.map(role => role.sale_operation_role.description) // Extrai o campo `description`
				.join('\n - '),
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Status do projeto',
		fullProject?.status.description,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Expectativa do cliente',
		fullProject?.customer_expectation,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Data prevista de embarque',
		fullProject?.expected_embarkation_date.split('-').reverse().join('/'),
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Regime de embarque',
		fullProject?.embarkation_regime.description,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Regime de embarque personalizado (dias)',
		fullProject?.embarkation_period ?? '',
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Quantidade de embarques',
		fullProject?.number_of_boarding,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Observações',
		fullProject?.observation,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	if (formValues.images && formValues.images.length > 0) {
		const marginY = 10; // Espaçamento inicial para cada página
		const maxWidth = pageWidth - 2 * marginX; // Largura máxima da imagem (com margens)

		// Adicionar um título para a seção de imagens
		y += 10;
		if (y + 10 > pageHeight) {
			doc.addPage();
			y = marginY;
		}
		doc.setFont('helvetica', 'bold');
		doc.setFontSize(14);
		doc.text('Imagens:', marginX, y);
		y += 10;

		// Processar e adicionar cada imagem
		for (const image of formValues.images) {
			// Adicionar o nome da imagem
			doc.setFont('helvetica', 'normal');
			doc.setFontSize(12);

			const imageName = image.name.split('/').pop() || '';
			doc.text(`- ${imageName}`, marginX, y);

			y += 8; // Espaço para o nome da imagem

			// Remover o prefixo Base64 se existir
			const processedBase64 = processBase64Image(image.contentBase64);

			// Criar uma nova imagem HTML para cálculo de proporções
			const img = new Image();
			img.src = `data:image/jpeg;base64,${processedBase64}`;

			// Garantir que a imagem seja carregada antes de continuar
			// eslint-disable-next-line no-loop-func
			await new Promise<void>(resolve => {
				img.onload = () => {
					// Calcular proporção
					const aspectRatio = img.width / img.height;
					let imageWidth = maxWidth;
					let imageHeight = maxWidth / aspectRatio;

					// Quebrar para nova página se não houver espaço suficiente
					if (y + imageHeight > pageHeight) {
						doc.addPage();
						y = marginY;
					}

					// Adicionar a imagem ao PDF
					doc.addImage(
						processedBase64,
						'JPEG',
						marginX,
						y,
						imageWidth,
						imageHeight
					);
					y += imageHeight + 10; // Adicionar espaço entre as imagens

					resolve();
				};
			});
		}
	}

	// Salva o arquivo como PDF
	const projectNumber = formValues.projectNumber || 'unknown';
	doc.save(`WO ${projectNumber}.pdf`);
};
