import { read, exclude, create, edit } from './httpService';

export interface IClientBackEnd {
	id: number;
	client_name: string;
	user_id: number;
}

export interface IPlatformBackEnd {
	id: number;
	platform_name: string;
	platform_code: string;
	client: IClientBackEnd;
}

export interface IPlatformOnlyBackEnd {
	id: number;
	platform_name: string;
	platform_code: string;
	client_id: number;
}

export interface IPlatformInput {
	platformName: string;
	platformCode: string;
	clientId: number | null;
}

export interface IUserInput {
	email: string;
	password: string;
	username: string;
	coordinator: boolean;
	module_role_id: number | null;
}

export interface IUserUpdate {
	id: number;
	email: string;
	password: string | null; // Torna o campo password opcional
	username: string;
	coordinator: boolean;
	module_role_id: number | null;
}

export interface IUserBackEnd {
	id: number;
	email: string;
	username: string;
	coordinator: boolean;
	module_role_id: number;
}

export interface IModuleRoleBackEnd {
	id: number;
	description: string;
}

export interface IContractCategoryBackEnd {
	id: number;
	description: string;
}

export interface IShiftBackEnd {
	id: number;
	description: string;
}

export interface IProjectClassificationBackEnd {
	id: number;
	description: string;
}

export interface IProjectScopeBackEnd {
	id: number;
	sow: string;
}

export interface IProjectScopeInput {
	sow: string;
}

export interface IProjectScopeDetaisInput {
	sowDetais: string;
	projectScopeId: number;
}

export interface IProjectScopeDetaisBackEnd {
	id: number;
	sow_detais: string;
	project_scope_id: number;
}

export interface IProjectScopeDetaisSowBackEnd {
	id: number;
	sow_detais: string;
	project_scope_id: number;
	sow: IProjectScopeBackEnd;
}

export interface IOperationRoleBackEnd {
	id: number;
	description: string;
}

export interface ISaleOperationRoleBackEnd {
	id: number;
	description: string;
}

export interface ISaleOperationRoleInput {
	description: string;
}

export interface IWorkerBackEnd {
	id: number;
	worker_name: string;
	worker_category_id: number;
	registration: number;
	operation_role_id: number;
	status: boolean;
	operation_role: IOperationRoleBackEnd;
	worker_skill: IWorkerSkill[];
}

export interface IWorkerNameBackEnd {
	id: number;
	worker_name: string;
}

export interface IMaterialBackEnd {
	id: number;
	description: string;
}

export interface IMaterialInput {
	description: string;
}

export interface IEmbarkationRegimeBackEnd {
	id: number;
	description: string;
}

export interface IBoardingStatusBackEnd {
	id: number;
	description: string;
}

export interface IBoardingStatusInput {
	description: string;
}

export interface IProjectNumberBackEnd {
	id: number;
	project_number: number;
	user_id: number;
	client_id: number;
	platform_id: number;
}

export interface IProjectNumberDrakeBackEnd {
	project_numbers: number[];
}

export interface IDrakePlatform {
	platform: string;
}

export interface IDrakeProjectNumber {
	wo_code: number;
}

export interface IProjectScopeIds {
	projectScopeId: number | '';
	projectScopeDetaisId: number | '';
}

export interface IMaterialInputForm {
	materialId: number | '';
	quantity: number | '';
}

export interface IImageBackend {
	name: string;
	contentBase64: string;
}

export interface IImageAction {
	image: IImageBackend; // Item da interface IImageOutputS3Backend
	action: 'add' | 'remove'; // Ação que pode ser "add" ou "remove"
}

export interface IProjectFormBackend {
	projectNumber: number | '';
	projectName: string;
	userId: number | '';
	clientId: number | '';
	platformId: number | '';
	purchaseOrder: string | '';
	proposal: string | '';
	projectClassificationId: number | '';
	contractCategoryId: number | '';
	scopeObservations: string;
	teamQuantity: number | '';
	teamSize: number | '';
	shiftId: number | '';
	customerExpectation: string;
	expectedEmbarkationDate: string;
	embarkationPeriod: number | null;
	embarkationRegime: string;
	numberOfBoarding: number | '';
	observation: string;
	boardingStatus: number | '';
	saleOperationRoleIds: number[];
	materials: IMaterialInputForm[];
	projectScopeIds: IProjectScopeIds[];
	images: IImageBackend[];
}

export interface IProjectScopeIdsBackend {
	project_id: number;
	project_scope_id: number;
	project_scope_detais_id: number;
	id: number;
	project_scope: {
		sow: string;
		id: number;
	};
	project_scope_detais: {
		sow_detais: string;
		id: number;
		project_scope_id: number;
	};
}

export interface IImageOutputBackend {
	id: number;
	image_url: string;
	project_id: number;
}

interface IImageSimple {
	image_url: string;
	project_id: number;
}

export interface IImageOutputS3Backend {
	image: IImageSimple;
	content_base_64: string;
}

export interface IFullProjectBackend {
	id: number;
	project_number: number;
	project_name: string;
	user: IUserBackEnd;
	client: IClientBackEnd;
	platform: IPlatformOnlyBackEnd;
	purchase_order: string;
	quote_number: string;
	project_classification: IProjectClassificationBackEnd;
	contract_category: IContractCategoryBackEnd;
	scope_observations: string;
	team_quantity: number;
	team_size: number;
	sale_operation_roles: IProjectSaleOperationRole[];
	shift: IShiftBackEnd;
	materials: IProjectMaterial[];
	customer_expectation: string;
	expected_embarkation_date: string;
	embarkation_period: number | null;
	expected_demobilization_date: string;
	embarkation_regime: IEmbarkationRegimeBackEnd;
	number_of_boarding: number | null;
	status: IBoardingStatusBackEnd;
	observation: string;
	project_scope_ids: IProjectScopeIdsBackend[];
	images: IImageOutputBackend[];
	// images: ImageOutputS3Backend[];
}

export interface IBoardingBackend {
	boarding_number: number;
	number_of_workers: number;
	planned_embarkation_date: string;
	planned_disembarkation_date: string;
	scheduled_embarkation_date: string;
	scheduled_disembarkation_date: string;
	actual_embarkation_date: string;
	actual_disembarkation_date: string;
	id: number;
	project: {
		project_number: number;
		project_name: string;
		user_id: number;
		user: {
			id: number;
			username: string;
			coordinator: boolean;
		};
		team_size: number;
		client: {
			client_name: string;
			id: number;
		};
		platform: {
			platform_name: string;
			platform_code: string;
			id: number;
		};
		project_classification: {
			description: string;
			id: number;
		};
		status: {
			description: string;
			id: number;
		};
		observation: {
			description: string;
		};
		project_scope_ids: IProjectScopeIdsBackend[];
	};
	boarding_status: {
		description: string;
		id: number;
	};
	observation: string;
	consider_disembark_day: boolean | null;
}

export interface IBoardingAdd {
	projectId: number;
	boardingNumber: number;
	numberOfWorkers: number;
	boardingStatus: number;
	planned_embarkation_date: string;
	planned_disembarkation_date: string;
	scheduled_embarkation_date: string;
	scheduled_disembarkation_date: string;
	actualEmbarkationDate: string | null;
	actualDisembarkationDate: string | null;
	observation: string;
	considerDisembarkDay: boolean | null;
}

export interface IDrakeWorkerBoardingBasicBackend {
	id: number;
	worker_id: number | null;
	boarding_id: number | null;
	actual_start_date: string;
	actual_end_date: string | null;
	event_uop_acronym: string;
	event_uop: string;
	event_acronym: string;
	event_description: string;
	wo_code: number;
	worker: {
		worker_name: string;
		worker_category_id: number;
		registration: number;
		operation_role_id: number;
		status: boolean;
	};
	boarding: {
		boarding_number: number;
		number_of_workers: number;
		planned_embarkation_date: string;
		planned_disembarkation_date: string;
		scheduled_embarkation_date: string;
		scheduled_disembarkation_date: string;
		actual_embarkation_date: string;
		actual_disembarkation_date: string;
		id: number;
		project: {
			id: number;
			project_number: number;
			user_id: number;
			client_id: number;
			platform_id: number;
		};
	};
}

export interface IWorkerBoardingBackEnd {
	id: number;
	worker_id: number | null;
	boarding_id: number;
	scheduled_start_date: string | null;
	scheduled_end_date: string | null;
	worker: IWorkerBackEnd | null;
	boarding: IBoardingBackend;
}

export interface IDrakeWorkerBoardingBackEnd {
	id: number;
	worker_id: number | null;
	boarding_id: number;
	actual_start_date: string | null;
	actual_end_date: string | null;
	worker: IWorkerBackEnd | null;
	boarding: IBoardingBackend;
}

export interface IEditBoardingForm {
	boardingNumber: number;
	boardingStatus: number;
	numberOfWorkers: number;
	planned_disembarkation_date: string | null;
	planned_embarkation_date: string | null;
	scheduled_disembarkation_date: string | null;
	scheduled_embarkation_date: string | null;
	actual_disembarkation_date: string | null;
	actual_embarkation_date: string | null;
	observation: string | null;
	considerDisembarkDay: boolean | null;
}

export interface IEditWorkerBoardingForm {
	worker_id: number | null;
	boarding_id: number | null;
	scheduled_start_date: string | null;
	scheduled_end_date: string | null;
}

export interface IProjectSaleOperationRole {
	id: number;
	project_id: number;
	sale_operation_role_id: number;
	sale_operation_role: ISaleOperationRoleBackEnd;
}

export interface IProjectMaterial {
	id: number;
	project_id: number;
	material_id: number;
	quantity: number;
	material: IMaterialBackEnd;
}

export interface ISkillsBoardingDate {
	operationRoleIds: (number | null)[];
	skillIds: (number | null)[];
	workerId: number | '';
	boardingStartDate: string | undefined;
	boardingEndDate: string | undefined;
	filterStandBy: boolean;
	filterUnscheduled: boolean;
}

export interface IWorkerBoardingUpdateOrAdd {
	boardindId: number | undefined;
	workerId: number;
	scheduledStartDate: string | undefined;
	scheduledEndDate: string | undefined;
}

export interface ITableRow {
	wo: number;
	coordinator: string;
	client: string;
	type: string;
	platform: string;
	scope: string;
	start_date: string; // Assuming date is represented as a string
	planned_days: number;
	team_size: number;
	status: string;
	situation: string;
	comments: string;
	number_of_workers: number[];
}

export interface ITotalWorkersMonthly {
	date: string;
	number_of_workers: number;
}

interface IDataSummary {
	planned: number;
	scheduled: number;
	actual: number;
	planned_scheduled_percentage: number;
	actual_scheduled_percentage: number;
	deviation_from_scheduling: number;
}

interface ITotalPerSituation {
	situation: string;
	number_of_workers: number[];
}

export interface IOperationalProjection {
	rows: ITableRow[];
	days: string[]; // Assuming date is represented as a string
	daily_sum: ITotalPerSituation[];
	workers_month: ITotalWorkersMonthly[];
	data_summary: IDataSummary;
}

export interface IWorkerHistogram {
	workers: {
		worker_name: string;
		operation_role: string;
		events: string[];
		actual_boarding: boolean;
	}[];
	days: string[];
}

export interface ITotalPerClient {
	client: string;
	number_of_workers_by_situation: ITotalPerSituation[];
}

export interface ITotalPerCoordinator {
	coordinator: string;
	number_of_workers_by_situation: ITotalPerSituation[];
}

export interface ActualPercentage {
	labels: string[];
	actual_by_planned: number[];
	actual_by_scheduled: number[];
}

export interface IPerformanceOverview {
	days: string[];
	daily_sum: ITotalPerSituation[];
	data_summary: IDataSummary;
	clients_sum: ITotalPerClient[];
	coordinators_sum: ITotalPerCoordinator[];
	actual_percentage_clients: ActualPercentage;
	actual_percentage_coordinators: ActualPercentage;
}

export async function apiGetAllPlatformsClients(): Promise<IPlatformBackEnd[]> {
	const allPlatformsClients = await read('/platform/client/list');
	return allPlatformsClients;
}

export async function apiGetAllPlatforms(): Promise<IPlatformOnlyBackEnd[]> {
	const allPlatforms = await read('/platform/list');
	return allPlatforms;
}

export async function apiGetAllSow(): Promise<IProjectScopeBackEnd[]> {
	const allSows = await read('/project_scope/list');
	return allSows;
}

export async function apiGetAllSaleOperationRole(): Promise<
	ISaleOperationRoleBackEnd[]
> {
	const allSaleOperationRole = await read('/sale_operation_role/list');
	return allSaleOperationRole;
}

export async function apiGetAllSowDetais(): Promise<
	IProjectScopeDetaisSowBackEnd[]
> {
	const allSowsDetais = await read('/project_scope_detais/sow/list');
	return allSowsDetais;
}

export interface ISkill {
	id: number;
	description: string;
}

export interface ISkillInput {
	description: string;
}

export interface ILevel {
	description: string;
	id: number;
}

export interface ILevelInput {
	description: string;
}

export interface ISkillLevel {
	id: number;
	skillId: number;
	levelId: number;
	skill: ISkill;
	level: ILevel;
}

export interface ISkillLevelInput {
	skillId: number | null;
	levelId: number | null;
}

export interface IWorkerSkill {
	skill_id: number;
	worker_id: number;
	level_id: number;
	id: number;
	skill: ISkill;
	level: ILevel;
}

export interface IWorkerSkillIds {
	skill_id: number;
	worker_id: number;
	level_id: number;
}

export async function apiGetAllUsers(): Promise<IUserBackEnd[]> {
	const allUser = await read('/user/list');
	return allUser;
}

export async function apiGetAllContractCategory(): Promise<
	IContractCategoryBackEnd[]
> {
	const allContractCategory = await read('/contract_category/list');
	return allContractCategory;
}

export async function apiGetAllShift(): Promise<IShiftBackEnd[]> {
	const allShift = await read('/shift/list');
	return allShift;
}

export async function apiGetAllProjectClassification(): Promise<
	IProjectClassificationBackEnd[]
> {
	const allContractCategory = await read('/project_classification/list');
	return allContractCategory;
}

export async function apiGetAllProjectScope(): Promise<IProjectScopeBackEnd[]> {
	const allProjectScope = await read('/project_scope/list');
	return allProjectScope;
}

export async function apiGetAllProjectScopeDetais(): Promise<
	IProjectScopeDetaisBackEnd[]
> {
	const allProjectScopeDetais = await read('/project_scope_detais/list');
	return allProjectScopeDetais;
}

export async function apiGetAllWorker(): Promise<IWorkerBackEnd[]> {
	const allWorker = await read('/worker/list');
	return allWorker;
}

export async function apiGetSaleOperationRole(): Promise<
	ISaleOperationRoleBackEnd[]
> {
	const allSaleOperationRole = await read('/sale_operation_role/list');
	return allSaleOperationRole;
}

export async function apiGetAllClient(): Promise<IClientBackEnd[]> {
	const allClient = await read('/client/list');
	return allClient;
}

export async function apiGetAllModuleRole(): Promise<IModuleRoleBackEnd[]> {
	const allModuleRole = await read('/module_role/list');
	return allModuleRole;
}

export async function apiGetAllWorkerNames(): Promise<IWorkerNameBackEnd[]> {
	const allWorkers = await read('/worker/list/name');
	return allWorkers;
}

export async function apiGetWorkerById(id: number): Promise<IWorkerBackEnd> {
	const worker = await read(`/worker/list/id/${id}`);
	return worker;
}

export async function apiGetFullProjectByProjectNumber(
	projecNumber: number
): Promise<IFullProjectBackend> {
	const fullProject = await read(`project/full-project/list/${projecNumber}`);
	return fullProject;
}

export async function apiUpdateWorkerSkill(
	id: number,
	workerSkillIds: IWorkerSkillIds
) {
	const updatedWorkerSkill = await edit(`/worker_skill/update/${id}`, {
		skill_id: workerSkillIds.skill_id,
		worker_id: workerSkillIds.worker_id,
		level_id: workerSkillIds.level_id,
	});
	return updatedWorkerSkill;
}

export async function apiUpdateClient(id: number, client: IClientAdd) {
	const updatedClient = await edit(`/client/update/${id}`, {
		client_name: client.clientName,
		user_id: client.userId,
	});
	return updatedClient;
}

export async function apiUpdateProjecScope(
	id: number,
	sow: IProjectScopeInput
) {
	await edit(`/project_scope/update/${id}`, {
		sow: sow.sow,
	});
}

export async function apiUpdateSaleOperationRole(
	id: number,
	saleOperationRole: ISaleOperationRoleInput
) {
	await edit(`/sale_operation_role/update/${id}`, {
		description: saleOperationRole.description,
	});
}

export async function apiUpdateMaterial(id: number, material: IMaterialInput) {
	await edit(`/material/update/${id}`, {
		description: material.description,
	});
}

export async function apiUpdateLevel(id: number, level: ILevelInput) {
	await edit(`/level/update/${id}`, {
		description: level.description,
	});
}

export async function apiUpdateSkill(id: number, skill: ISkillInput) {
	await edit(`/skill/update/${id}`, {
		description: skill.description,
	});
}

export async function apiUpdateSkillLevel(id: number, skill: ISkillLevelInput) {
	await edit(`/skill_level/update/${id}`, {
		skill_id: skill.skillId,
		level_id: skill.levelId,
	});
}

export async function apiUpdateBoardingStatus(
	id: number,
	boardingStatus: IBoardingStatusInput
) {
	await edit(`/boarding_status/update/${id}`, {
		description: boardingStatus.description,
	});
}

export async function apiUpdateProjecScopeDetais(
	id: number,
	sow: IProjectScopeDetaisInput
) {
	await edit(`/project_scope_detais/update/${id}`, {
		sow_detais: sow.sowDetais,
		project_scope_id: sow.projectScopeId,
	});
}

export async function apiUpdatePlatform(id: number, platform: IPlatformInput) {
	await edit(`/platform/update/${id}`, {
		platform_name: platform.platformName,
		platform_code: platform.platformCode,
		client_id: platform.clientId,
	});
}

export async function apiUpdateUser(id: number, user: IUserUpdate) {
	const payload: any = {
		email: user.email,
		username: user.username,
		coordinator: user.coordinator,
		module_role_id: user.module_role_id,
		password: user.password,
	};

	await edit(`/user/update/${id}`, payload);
}

export async function apiCreateWorkerSkill(
	workerSkillIds: IWorkerSkillIds
): Promise<object> {
	const newWorkerSkill = await create('/worker_skill/add', {
		skill_id: workerSkillIds.skill_id,
		worker_id: workerSkillIds.worker_id,
		level_id: workerSkillIds.level_id,
	});

	return newWorkerSkill;
}

export interface IClientBackend {
	id: number;
	client_name: string;
	user_id: number | null;
}

export interface IClientAdd {
	clientName: string;
	userId: number | null;
}

export interface IProjectScopeSowDetaisInput {
	sowDetais: string;
	projectScopeId: number | null;
}

export async function apiCreateClient(client: IClientAdd): Promise<void> {
	await create('/client/add', {
		client_name: client.clientName,
		user_id: client.userId,
	});
}

export async function apiCreateProjectScope(
	projectScope: IProjectScopeInput
): Promise<void> {
	await create('/project_scope/add', {
		sow: projectScope.sow,
	});
}

export async function apiCreateSaleOperationRole(
	saleOperationRole: ISaleOperationRoleInput
): Promise<void> {
	await create('/sale_operation_role/add', {
		description: saleOperationRole.description,
	});
}

export async function apiCreateMaterial(
	material: IMaterialInput
): Promise<void> {
	await create('/material/add', {
		description: material.description,
	});
}

export async function apiCreateLevel(level: ILevelInput): Promise<void> {
	await create('/level/add', {
		description: level.description,
	});
}

export async function apiCreateSkill(skill: ISkillInput): Promise<void> {
	await create('/skill/add', {
		description: skill.description,
	});
}

export async function apiCreateSkillLevel(
	skilLevell: ISkillLevelInput
): Promise<void> {
	await create('/skill_level/add', {
		skill_id: skilLevell.skillId,
		level_id: skilLevell.levelId,
	});
}

export async function apiCreateBoardingStatus(
	boardingStatus: IBoardingStatusInput
): Promise<void> {
	await create('/boarding_status/add', {
		description: boardingStatus.description,
	});
}

export async function apiCreateProjectScopeDetais(
	projectScopeDetais: IProjectScopeSowDetaisInput
): Promise<void> {
	await create('/project_scope_detais/add', {
		sow_detais: projectScopeDetais.sowDetais,
		project_scope_id: projectScopeDetais.projectScopeId,
	});
}

export async function apiCreatePlatform(
	platform: IPlatformInput
): Promise<void> {
	await create('/platform/add', {
		platform_name: platform.platformName,
		platform_code: platform.platformCode,
		client_id: platform.clientId,
	});
}

export async function apiCreateUser(user: IUserInput): Promise<void> {
	await create('/user/register', {
		email: user.email,
		password: user.password,
		username: user.username,
		coordinator: user.coordinator,
		module_role_id: user.module_role_id,
	});
}

export async function apiCreateBoarding(
	boardingAdd: IBoardingAdd,
	projectNumber: number
): Promise<void> {
	await create(`/boarding/add?project_number=${projectNumber}`, {
		boarding_number: boardingAdd.boardingNumber,
		number_of_workers: boardingAdd.numberOfWorkers,
		planned_embarkation_date: boardingAdd.planned_embarkation_date,
		planned_disembarkation_date: boardingAdd.planned_disembarkation_date,
		scheduled_embarkation_date: boardingAdd.scheduled_embarkation_date,
		scheduled_disembarkation_date: boardingAdd.scheduled_disembarkation_date,
		actual_embarkation_date: boardingAdd.actualEmbarkationDate,
		actual_disembarkation_date: boardingAdd.actualDisembarkationDate,
		observation: boardingAdd.observation,
		project_id: boardingAdd.projectId,
		boarding_status_id: boardingAdd.boardingStatus,
		consider_disembark_day: boardingAdd.considerDisembarkDay,
	});
}

export async function apiGetAllMaterial(): Promise<IMaterialBackEnd[]> {
	const allMaterial = await read('/material/list');
	return allMaterial;
}

export async function apiGetAllEmbarkationRegime(): Promise<
	IEmbarkationRegimeBackEnd[]
> {
	const allEmbarkationRegime = await read('/embarkation_regime/list');
	return allEmbarkationRegime;
}

export async function apiGetAllBoardingStatus(): Promise<
	IBoardingStatusBackEnd[]
> {
	const allBoardingStatus = await read('/boarding_status/list');
	return allBoardingStatus;
}

export async function apiCreateProject(
	projectForm: IProjectFormBackend
): Promise<object> {
	const newProject = await create('/project/add', {
		project_number: projectForm.projectNumber,
		project_name: projectForm.projectName,
		user_id: projectForm.userId,
		client_id: projectForm.clientId,
		platform_id: projectForm.platformId,
		purchase_order: projectForm.purchaseOrder,
		quote_number: projectForm.proposal,
		project_classification_id: projectForm.projectClassificationId,
		contract_category_id: projectForm.contractCategoryId,
		scope_observations: projectForm.scopeObservations,
		team_quantity: projectForm.teamQuantity,
		team_size: projectForm.teamSize,
		shift_id: projectForm.shiftId,
		customer_expectation: projectForm.customerExpectation,
		expected_embarkation_date: projectForm.expectedEmbarkationDate,
		embarkation_period: projectForm.embarkationPeriod,
		embarkation_regime_id: projectForm.embarkationRegime,
		number_of_boarding: projectForm.numberOfBoarding,
		observation: projectForm.observation,
		boarding_status_id: projectForm.boardingStatus,
		sale_operation_role_ids: projectForm.saleOperationRoleIds,
		materials: projectForm.materials.map(item => ({
			material_id: item.materialId,
			quantity: item.quantity,
		})),
		project_scope_ids: projectForm.projectScopeIds.map(item => ({
			project_scope_id: item.projectScopeId,
			project_scope_detais_id: item.projectScopeDetaisId,
		})),
	});

	return newProject;
}

export async function apiCreateImage(
	project_number: number | '',
	image: IImageBackend
): Promise<void> {
	const payload = {
		project_number: project_number || null, // Garantindo que não será enviado um número vazio
		image_input: {
			name: image.name,
			content_base_64: image.contentBase64, // Aderindo ao padrão de nomes na API
		},
	};

	await create('/image/add', payload);
}

export async function apiDeleteAllImages(projectNumber: number | '') {
	await exclude(`/image/delete/all/project-number/${projectNumber}`);
}

export async function apiGetAllProjectNumber(): Promise<
	IProjectNumberBackEnd[]
> {
	const allProjectNumber = await read('project/project-number/list');
	return allProjectNumber;
}

export async function apiGetAllProjectNumberDrake(): Promise<IProjectNumberDrakeBackEnd> {
	const allProjectNumber = await read('project/project-number/with-drake/list');
	return allProjectNumber;
}

export async function apiGetAllDrakePlatform(): Promise<IDrakePlatform[]> {
	const allDrakePlatforms = await read('drake-worker-boarding/list/platforms');
	return allDrakePlatforms;
}

export async function apiGetAllDrakeProjectNumber(): Promise<
	IDrakeProjectNumber[]
> {
	const allDrakeProjectNumber = await read(
		'drake-worker-boarding/list/wo-codes'
	);
	return allDrakeProjectNumber;
}

export async function apiGetImage(id: number): Promise<IImageOutputS3Backend> {
	const image = await read(`image/list/${id}`);
	return image;
}

export async function apiGetLastProjectNumber(): Promise<IProjectNumberBackEnd> {
	const lastProjectNumber = await read('project/get-last-project');
	return lastProjectNumber;
}

export async function apiGetAllBoarding(): Promise<IBoardingBackend[]> {
	const allBoarding = await read('boarding/list');
	return allBoarding;
}

export async function apiGetAllBoardingLast20(): Promise<IBoardingBackend[]> {
	const last20Boarding = await read('boarding/list/last20');
	return last20Boarding;
}

export async function apiGetBoardingByProjectNumber(
	projectNumber: number
): Promise<IBoardingBackend[]> {
	const boarding = await read(
		`boarding/list/project-number?project_number=${projectNumber}`
	);
	return boarding;
}

export async function apiGetWorkerBoardingByBoardingId(
	boardingId: number
): Promise<IWorkerBoardingBackEnd[]> {
	const workerBoardings = await read(
		// `/worker-boarding/boarding-id/list?boarding_id=${boardingId}`
		`/worker-boarding/boarding-id/list/?boarding_id=${boardingId}`
	);
	return workerBoardings;
}

export async function apiGetDrakeWorkerBoardingByBoardingId(
	boardingId: number
): Promise<IDrakeWorkerBoardingBackEnd[]> {
	const drakeWorkerBoardings = await read(
		// `/drake-worker-boarding/boarding-id/list?boarding_id=${boardingId}`
		`/drake-worker-boarding/boarding-id/list/?boarding_id=${boardingId}`
	);
	return drakeWorkerBoardings;
}

export async function apiGetWorkerBoardingByCriteria(
	startDate: string,
	endDate: string,
	selectedClientId: number | '',
	selectedPlatformId: number | '',
	selectedProjectNumber: number | '',
	selectedProjectScopeId: number | '',
	selectedProjectClassificationId: number | '',
	selectedUserId: number | ''
): Promise<IWorkerBoardingBackEnd[]> {
	// Inicialize uma matriz para armazenar os parâmetros de consulta
	const queryParams: string[] = [];

	// Adicione cada parâmetro de consulta à matriz se não estiver vazio ('')
	if (startDate !== '') queryParams.push(`start_date=${startDate}`);
	if (endDate !== '') queryParams.push(`end_date=${endDate}`);
	if (selectedClientId !== '')
		queryParams.push(`client_id=${selectedClientId}`);
	if (selectedPlatformId !== '')
		queryParams.push(`platform_id=${selectedPlatformId}`);
	if (selectedProjectNumber !== '')
		queryParams.push(`project_number=${selectedProjectNumber}`);
	if (selectedProjectScopeId !== '')
		queryParams.push(`scope_id=${selectedProjectScopeId}`);
	if (selectedProjectClassificationId !== '')
		queryParams.push(
			`project_classification=${selectedProjectClassificationId}`
		);
	if (selectedUserId !== '')
		queryParams.push(`coordinator_id=${selectedUserId}`);

	// Concatene os parâmetros de consulta em uma única string separada por '&'
	const queryString = queryParams.join('&');

	// Construa a URL final
	// const url = `/worker-boarding/list?${queryString}`;
	const url = `/worker-boarding/list/?${queryString}`;

	// Faça a solicitação com a URL construída
	const workerBoardings = await read(url);
	return workerBoardings;
}

export async function apiGetWorkerBoardingByWorkerId(
	workerId: number | '',
	startDate: string | null,
	endDate: string | null
): Promise<IWorkerBoardingBackEnd[]> {
	const queryParams: string[] = [];
	if (workerId !== '') queryParams.push(`worker_id=${workerId}`);
	if (startDate !== '') queryParams.push(`start_date=${startDate}`);
	if (endDate !== '') queryParams.push(`end_date=${endDate}`);

	const queryString = queryParams.join('&');
	const url = `/worker-boarding/list/worker-id/?${queryString}`;

	const workerBoardings = await read(url);
	return workerBoardings;
}

export async function apiGetOperationalProjection(
	startDate: string,
	endDate: string,
	selectedClientId: number | '',
	selectedPlatformId: number | '',
	selectedProjectNumber: number | '',
	selectedProjectScopeId: number | '',
	selectedProjectClassificationId: number | '',
	selectedUserId: number | ''
): Promise<IOperationalProjection> {
	const queryParams: string[] = [];

	if (startDate !== '') queryParams.push(`start_date=${startDate}`);
	if (endDate !== '') queryParams.push(`end_date=${endDate}`);
	if (selectedClientId !== '')
		queryParams.push(`client_id=${selectedClientId}`);
	if (selectedPlatformId !== '')
		queryParams.push(`platform_id=${selectedPlatformId}`);
	if (selectedProjectNumber !== '')
		queryParams.push(`project_number=${selectedProjectNumber}`);
	if (selectedProjectScopeId !== '')
		queryParams.push(`scope_id=${selectedProjectScopeId}`);
	if (selectedProjectClassificationId !== '')
		queryParams.push(
			`project_classification=${selectedProjectClassificationId}`
		);
	if (selectedUserId !== '')
		queryParams.push(`coordinator_id=${selectedUserId}`);

	const queryString = queryParams.join('&');

	// const url = `/operational-projection/list?${queryString}`;
	const url = `/operational-projection/list/?${queryString}`;

	const operationalProjection = await read(url);

	// Ordenando o vetor rows com tipos definidos
	operationalProjection.rows.sort((a: ITableRow, b: ITableRow) => {
		// Primeiro compara o campo wo
		if (a.wo !== b.wo) {
			return a.wo - b.wo;
		}
		// Se wo for igual, compara o campo situation
		return a.situation.localeCompare(b.situation);
	});

	return operationalProjection;
}

export async function apiGetBoardingsByFilters(
	selectedUserId: number | '',
	selectedClientId: number | '',
	selectedPlatformId: number | '',
	selectedProjectNumber: number | '',
	startDate: string | '',
	endDate: string | ''
): Promise<IBoardingBackend[]> {
	const queryParams: string[] = [];

	if (selectedUserId !== '')
		queryParams.push(`coordinator_id=${selectedUserId}`);
	if (selectedClientId !== '')
		queryParams.push(`client_id=${selectedClientId}`);
	if (selectedPlatformId !== '')
		queryParams.push(`platform_id=${selectedPlatformId}`);
	if (selectedProjectNumber !== '')
		queryParams.push(`project_number=${selectedProjectNumber}`);
	if (startDate !== '') queryParams.push(`start_date=${startDate}`);
	if (endDate !== '') queryParams.push(`end_date=${endDate}`);

	const queryString = queryParams.join('&');

	// BARRA REMOVIDA
	const url = `/boarding/list/criteria?${queryString}`;
	// const url = `/boarding/list/criteria/?${queryString}`;

	const boardings = await read(url);
	return boardings;
}

export async function apiGetDrakeWorkerBoardingsBasic(
	startDate: string | '',
	endDate: string | '',
	platform: string | '',
	event_description_start_with: string | '',
	projectNumber: number | ''
): Promise<IDrakeWorkerBoardingBasicBackend[]> {
	const queryParams: string[] = [];

	if (startDate !== '') queryParams.push(`start_date=${startDate}`);
	if (endDate !== '') queryParams.push(`end_date=${endDate}`);
	if (platform !== '') queryParams.push(`platform=${platform}`);
	if (event_description_start_with !== '')
		queryParams.push(
			`event_description_start_with=${event_description_start_with}`
		);
	if (projectNumber !== '') queryParams.push(`project_number=${projectNumber}`);

	const queryString = queryParams.join('&');

	const url = `/drake-worker-boarding/list/basic?${queryString}`;
	// const url = `/drake-worker-boarding/list/basic/?${queryString}`;

	const boardings = await read(url);
	return boardings;
}

export async function apiUpdateDrakeBoardingId(
	id: number | null,
	boardingId: number | null
) {
	const updatedDrakeWorkerBoarding = await edit(
		`/drake-worker-boarding/update/boarding-id/${id}?boarding_id=${boardingId}`,
		{
			boarding_id: boardingId,
		}
	);
	return updatedDrakeWorkerBoarding;
}

export async function apiGetPerformanceOverview(
	startDate: string,
	endDate: string,
	selectedClientId: number | '',
	selectedPlatformId: number | '',
	selectedProjectNumber: number | '',
	selectedProjectScopeId: number | '',
	selectedProjectClassificationId: number | '',
	selectedUserId: number | ''
): Promise<IPerformanceOverview> {
	const queryParams: string[] = [];

	if (startDate !== '') queryParams.push(`start_date=${startDate}`);
	if (endDate !== '') queryParams.push(`end_date=${endDate}`);
	if (selectedClientId !== '')
		queryParams.push(`client_id=${selectedClientId}`);
	if (selectedPlatformId !== '')
		queryParams.push(`platform_id=${selectedPlatformId}`);
	if (selectedProjectNumber !== '')
		queryParams.push(`project_number=${selectedProjectNumber}`);
	if (selectedProjectScopeId !== '')
		queryParams.push(`scope_id=${selectedProjectScopeId}`);
	if (selectedProjectClassificationId !== '')
		queryParams.push(
			`project_classification=${selectedProjectClassificationId}`
		);
	if (selectedUserId !== '')
		queryParams.push(`coordinator_id=${selectedUserId}`);

	const queryString = queryParams.join('&');

	// const url = `/performance-overview/list?${queryString}`;
	const url = `/performance-overview/list/?${queryString}`;

	const performanceOverview = await read(url);
	return performanceOverview;
}

export async function apiGetWorkerHistogram(
	scheduled: boolean,
	startDate: string,
	endDate: string,
	selectedClientId: number | '',
	selectedPlatformId: number | '',
	selectedProjectNumber: number | '',
	selectedProjectScopeId: number | '',
	selectedProjectClassificationId: number | '',
	selectedUserId: number | '',
	selectedOperationRoleIds: number[],
	selectedWorkersIds: number[],
	selectedSkills: ISkill[]
): Promise<IWorkerHistogram> {
	const queryParams: string[] = [];

	if (startDate !== '') queryParams.push(`start_date=${startDate}`);
	if (endDate !== '') queryParams.push(`end_date=${endDate}`);
	if (selectedClientId !== '')
		queryParams.push(`client_id=${selectedClientId}`);
	if (selectedPlatformId !== '')
		queryParams.push(`platform_id=${selectedPlatformId}`);
	if (selectedProjectNumber !== '')
		queryParams.push(`project_number=${selectedProjectNumber}`);
	if (selectedProjectScopeId !== '')
		queryParams.push(`scope_id=${selectedProjectScopeId}`);
	if (selectedProjectClassificationId !== '')
		queryParams.push(
			`project_classification=${selectedProjectClassificationId}`
		);
	if (selectedUserId !== '')
		queryParams.push(`coordinator_id=${selectedUserId}`);
	if (selectedOperationRoleIds.length > 0) {
		selectedOperationRoleIds.forEach(operationRole => {
			queryParams.push(`operation_role_ids=${operationRole}`);
		});
	}
	if (selectedWorkersIds.length > 0) {
		selectedWorkersIds.forEach(worker => {
			queryParams.push(`worker_ids=${worker}`);
		});
	}
	if (selectedSkills.length > 0) {
		selectedSkills.forEach(skill => {
			queryParams.push(`skill_ids=${skill.id}`);
		});
	}

	const queryString = queryParams.join('&');

	const url = scheduled
		? `/worker_histogram/list/scheduled/?${queryString}`
		: `/worker_histogram/list/?${queryString}`;

	const workerHistogram = await read(url);
	return workerHistogram;
}

export async function apiUpdateBoarding(
	boardingId: number,
	boardingForm: IEditBoardingForm
) {
	const updatedBoarding = await edit(`/boarding/update/${boardingId}`, {
		number_of_workers: boardingForm.numberOfWorkers,
		scheduled_embarkation_date: boardingForm.scheduled_embarkation_date,
		scheduled_disembarkation_date: boardingForm.scheduled_disembarkation_date,
		actual_embarkation_date: boardingForm.actual_embarkation_date,
		actual_disembarkation_date: boardingForm.actual_disembarkation_date,
		observation: boardingForm.observation,
		boarding_status_id: boardingForm.boardingStatus,
		consider_disembark_day: boardingForm.considerDisembarkDay,
	});
	return updatedBoarding;
}

export async function apiUpdateProject(
	projectId: number,
	projectForm: IProjectFormBackend
) {
	const updatedProject = await edit(`/project/update/${projectId}`, {
		project_number: projectForm.projectNumber,
		project_name: projectForm.projectName,
		user_id: projectForm.userId,
		client_id: projectForm.clientId,
		platform_id: projectForm.platformId,
		purchase_order: projectForm.purchaseOrder,
		quote_number: projectForm.proposal,
		project_classification_id: projectForm.projectClassificationId,
		contract_category_id: projectForm.contractCategoryId,
		scope_observations: projectForm.scopeObservations,
		team_quantity: projectForm.teamQuantity,
		team_size: projectForm.teamSize,
		shift_id: projectForm.shiftId,
		customer_expectation: projectForm.customerExpectation,
		expected_embarkation_date: projectForm.expectedEmbarkationDate,
		embarkation_period: projectForm.embarkationPeriod,
		embarkation_regime_id: projectForm.embarkationRegime,
		number_of_boarding: projectForm.numberOfBoarding,
		observation: projectForm.observation,
		boarding_status_id: projectForm.boardingStatus,
		sale_operation_roles: projectForm.saleOperationRoleIds,
		materials: projectForm.materials.map(item => ({
			material_id: item.materialId,
			quantity: item.quantity,
		})),
		project_scope_ids: projectForm.projectScopeIds.map(item => ({
			project_scope_id: item.projectScopeId,
			project_scope_detais_id: item.projectScopeDetaisId,
		})),
		// images: projectForm.images.map(item => ({
		// 	name: item.name,
		// 	content_base_64: item.contentBase64,
		// })),
	});
	return updatedProject;
}

export async function apiUpdateWorkerBoarding(
	workerBoardingId: number,
	workerBoardingForm: IEditWorkerBoardingForm
) {
	const updatedBoarding = await edit(
		`/worker-boarding/update/${workerBoardingId}`,
		{
			worker_id: workerBoardingForm.worker_id,
			boarding_id: workerBoardingForm.boarding_id,
			scheduled_start_date: workerBoardingForm.scheduled_start_date,
			scheduled_end_date: workerBoardingForm.scheduled_end_date,
		}
	);
	return updatedBoarding;
}

export async function apiGetSaleOperationRoleByProjectNumber(
	projectNumber: number
): Promise<IProjectSaleOperationRole[]> {
	const projectSaleOperationRole = await read(
		`/project_sale_operation_role/list/project-number?project_number=${projectNumber}`
	);
	return projectSaleOperationRole;
}

export async function apiGetAllSkills(): Promise<ISkill[]> {
	const allSkills = await read('/skill/list');
	return allSkills;
}

export async function apiGetAllLevels(): Promise<ILevel[]> {
	const allLevels = await read('/level/list');
	return allLevels;
}

export async function apiGetAllSkillLevels(): Promise<ISkillLevel[]> {
	const allSkillLevels = await read('/skill_level/list');
	return allSkillLevels;
}

export async function apiGeLevelBySkillId(skillId: number): Promise<ILevel[]> {
	const levels = await read(`level/list/skill-id/${skillId}`);
	return levels;
}

export async function apiGetAllOperationRoles(): Promise<
	IOperationRoleBackEnd[]
> {
	const AllOperationRoles = await read('/operation_role/list');
	return AllOperationRoles;
}

export async function apiGetWorkersBySkillsDateAvailability(
	skillsBoardingDate: ISkillsBoardingDate
): Promise<IWorkerBackEnd[]> {
	const queryParams: string[] = [];

	if (skillsBoardingDate.boardingStartDate) {
		queryParams.push(
			`boarding_start_date=${skillsBoardingDate.boardingStartDate}`
		);
	}
	if (skillsBoardingDate.boardingEndDate) {
		queryParams.push(`boarding_end_date=${skillsBoardingDate.boardingEndDate}`);
	}
	queryParams.push(`filter_stand_by=${skillsBoardingDate.filterStandBy}`);
	queryParams.push(
		`filter_unscheduled=${skillsBoardingDate.filterUnscheduled}`
	);
	if (skillsBoardingDate.operationRoleIds.length > 0) {
		skillsBoardingDate.operationRoleIds.forEach(id => {
			queryParams.push(`operation_role_ids=${id}`);
		});
	}
	if (skillsBoardingDate.skillIds.length > 0) {
		skillsBoardingDate.skillIds.forEach(id => {
			queryParams.push(`skill_ids=${id}`);
		});
	}
	if (skillsBoardingDate.workerId) {
		queryParams.push(`worker_id=${skillsBoardingDate.workerId}`);
	}

	const queryString = queryParams.join('&');
	const url = `/worker/list/skills-date-availability?${queryString}`;

	const filteredWorkers = await read(url);
	return filteredWorkers;
}

export async function apiUpdateOrAddWorkerBoarding(
	workerBoarding: IWorkerBoardingUpdateOrAdd
): Promise<string> {
	const updatedAddedBoarding = await edit(`/worker-boarding/update-add/`, {
		worker_id: workerBoarding.workerId,
		boarding_id: workerBoarding.boardindId,
		scheduled_start_date: workerBoarding.scheduledStartDate,
		scheduled_end_date: workerBoarding.scheduledEndDate,
	});
	return updatedAddedBoarding;
}

export async function apiDeleteImage(imageId: number) {
	await exclude(`/image/delete/${imageId}`);
}

export async function apiDeleteWorkerBoarding(workerBoardingId: number) {
	await exclude(`/worker-boarding/delete/${workerBoardingId}`);
}

export async function apiDeleteBoarding(boardingId: number) {
	await exclude(`/boarding/delete/${boardingId}`);
}

export async function apiDeleteClient(clientId: number) {
	await exclude(`/client/delete/${clientId}`);
}

export async function apiDeleteMaterial(materialId: number) {
	await exclude(`/material/delete/${materialId}`);
}

export async function apiDeleteLevel(levelId: number) {
	await exclude(`/level/delete/${levelId}`);
}

export async function apiDeleteSow(sowId: number) {
	await exclude(`/project_scope/delete/${sowId}`);
}

export async function apiDeleteSowDetais(sowDetaisId: number) {
	await exclude(`/project_scope_detais/delete/${sowDetaisId}`);
}

export async function apiDeletePlatform(platformId: number) {
	await exclude(`/platform/delete/${platformId}`);
}

export async function apiDeleteBoardingStatus(boardingStatusId: number) {
	await exclude(`/boarding_status/delete/${boardingStatusId}`);
}

export async function apiDeleteSaleOperationRole(saleOperationRoleId: number) {
	await exclude(`/sale_operation_role/delete/${saleOperationRoleId}`);
}

export async function apiDeleteSkill(skillId: number) {
	await exclude(`/skill/delete/${skillId}`);
}

export async function apiDeleteSkillLevel(skillLevelId: number) {
	await exclude(`/skill_level/delete/${skillLevelId}`);
}

export async function apiDeleteProject(projectId: number) {
	await exclude(`/project/delete/${projectId}`);
}

export async function apiDeleteProjectByProjectNumber(
	projectNumber: number | ''
) {
	await exclude(`/project/delete/project-number/${projectNumber}`);
}

export async function apiGetBoardingById(
	id: number
): Promise<IBoardingBackend[]> {
	const boarding = await read(`/boarding/list/id/${id}`);
	return boarding;
}

// export async function apiGetAllFlashCards() {
// 	const allFlashCards = await read('/flashcards');
// 	return allFlashCards;
// }

// export async function apiDeleteFlashCard(cardId) {
//   await exclude(`/flashcards/${cardId}`);
// }

// export async function apiCreateFlashCard(title, description) {
//   const newFlashCard = create('/flashcards', {
//     id: getNewId(),
//     title,
//     description,
//   });

//   return newFlashCard;
// }

// export async function apiUpdateFlashCard(cardId, title, description) {
//   const updatedFlashCard = edit(`/flashcards/${cardId}`, {
//     title,
//     description,
//   });

//   return updatedFlashCard;
// } return updatedFlashCard;
// }
