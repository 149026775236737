import { jsPDF } from 'jspdf';
import {
	IBoardingStatusBackEnd,
	IClientBackEnd,
	IContractCategoryBackEnd,
	IEmbarkationRegimeBackEnd,
	IFullProjectBackend,
	IImageBackend,
	IMaterialBackEnd,
	IPlatformBackEnd,
	IProjectClassificationBackEnd,
	IProjectScopeBackEnd,
	IProjectScopeDetaisBackEnd,
	ISaleOperationRoleBackEnd,
	IUserBackEnd,
} from './apiService';

// Função para adicionar um campo ao PDF
const addFieldToPDF = (
	doc: jsPDF,
	key: string,
	value: any,
	pageWidth: number,
	y: number,
	lineHeight: number,
	marginX: number,
	pageHeight: number
): number => {
	const displayValue =
		typeof value === 'object' ? JSON.stringify(value, null, 2) : value;

	doc.setDrawColor(0);
	doc.setLineWidth(0.5);
	doc.line(marginX, y, pageWidth - marginX, y);
	y += 8;

	const lines: string[] = doc.splitTextToSize(`${key}: ${displayValue}`, 190);

	lines.forEach((line: string) => {
		doc.setFontSize(13);
		doc.text(line, marginX, y);
		y += lineHeight;

		if (y > pageHeight) {
			doc.addPage();
			y = 10;
		}
	});

	y += 5;
	return y;
};

export const handleDownloadPDFregistration = async (
	formValues: any,
	dropdownClient: IClientBackEnd[],
	filteredPlatforms: IPlatformBackEnd[],
	dropdownContractCategory: IContractCategoryBackEnd[],
	dropdownUser: IUserBackEnd[],
	dropdownProjectScope: IProjectScopeBackEnd[],
	dropdownProjectScopeDetais: IProjectScopeDetaisBackEnd[],
	dropdownProjectClassification: IProjectClassificationBackEnd[],
	dropdownShift: IContractCategoryBackEnd[],
	dropdownSaleOperationRole: ISaleOperationRoleBackEnd[],
	dropdownMaterial: IMaterialBackEnd[],
	dropdownEmbarkationRegime: IEmbarkationRegimeBackEnd[],
	dropdownBoardingStatus: IBoardingStatusBackEnd[],
	images: IImageBackend[]
) => {
	const doc = new jsPDF({
		orientation: 'portrait',
		unit: 'mm',
		format: 'a4',
	});

	// Largura da página
	const pageWidth = doc.internal.pageSize.getWidth();

	const imagePath = './qualitech.jpeg';

	const loadImageAsBase64 = (path: string): Promise<string> =>
		new Promise((resolve, reject) => {
			const img = new Image();
			img.src = path;
			img.onload = () => {
				const canvas = document.createElement('canvas');
				canvas.width = img.width;
				canvas.height = img.height;
				const ctx = canvas.getContext('2d');
				if (ctx) {
					ctx.drawImage(img, 0, 0);
					resolve(canvas.toDataURL('image/jpeg'));
				} else {
					reject(new Error('Não foi possível obter o contexto da imagem.'));
				}
			};
			img.onerror = () => reject(new Error('Erro ao carregar a imagem.'));
		});

	try {
		const base64Image = await loadImageAsBase64(imagePath);

		// Imagem no canto superior direito
		const imageWidth = 42;
		const imageHeight = 42;
		const marginRight = 10;
		const imageX = pageWidth - imageWidth - marginRight;
		const imageY = 10;

		doc.addImage(base64Image, 'JPEG', imageX, imageY, imageWidth, imageHeight);
	} catch (error) {
		console.error('Erro ao carregar a imagem:', error);
	}

	// Data
	const today = new Date();
	const formattedDate = `${today.getDate().toString().padStart(2, '0')}/${(
		today.getMonth() + 1
	)
		.toString()
		.padStart(2, '0')}/${today.getFullYear()}`;

	// Escolhemos y=55 para imprimir ambos na mesma linha
	const lineY = 55;

	// 1) Título maior à esquerda
	doc.setFont('helvetica', 'bold');
	doc.setFontSize(18);
	doc.text(`Projeto cadastrado: WO ${formValues.projectNumber}`, 10, lineY);

	// 2) Texto "Documento gerado..." à direita, em itálico e fonte menor
	doc.setFont('helvetica', 'italic');
	doc.setFontSize(12);
	const dateText = `Documento gerado: ${formattedDate}`;
	const dateTextWidth = doc.getTextWidth(dateText);
	const dateTextX = pageWidth - dateTextWidth - 10;
	doc.text(dateText, dateTextX, lineY);

	// >>> Retorna a fonte para normal para evitar que todo o texto fique em itálico
	doc.setFont('helvetica', 'normal');

	// Agora seguimos com o corpo do PDF
	let y = 65; // Início dos campos
	const pageHeight = 280;
	const lineHeight = 8;
	const marginX = 10;

	y = addFieldToPDF(
		doc,
		'Número do projeto',
		formValues?.projectNumber,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'PO',
		formValues?.purchaseOrder,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Nome do projeto',
		formValues?.projectName,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Proposta',
		formValues?.proposal,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Cliente',
		dropdownClient.find(client => client.id === formValues.clientId)
			?.client_name,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Plataforma',
		filteredPlatforms.find(p => p.id === formValues.platformId)?.platform_name,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Categoria do contrato',
		dropdownContractCategory.find(c => c.id === formValues.contractCategoryId)
			?.description,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Coordenador',
		dropdownUser.find(u => u.id === formValues.userId)?.username,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Quantidade de escopos',
		formValues?.scopeCount,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Escopo do projeto',
		'\n - ' +
			formValues?.projectScopeIds
				.map(
					(scope: { projectScopeId: number; projectScopeDetaisId: number }) =>
						`${
							dropdownProjectScope.find(u => u.id === scope.projectScopeId)?.sow
						} / ${
							dropdownProjectScopeDetais.find(
								u => u.id === scope.projectScopeDetaisId
							)?.sow_detais
						} (DETAIS)`
				)
				.join('\n - '),
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Classificação do projeto',
		dropdownProjectClassification.find(
			u => u.id === formValues.projectClassificationId
		)?.description,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Observações de escopo',
		formValues?.scopeObservations,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Quantidade de equipes',
		formValues?.teamQuantity,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Turno',
		dropdownShift.find(u => u.id === formValues.shiftId)?.description,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Número total de pessoas',
		formValues?.teamSize,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Perfis dos funcionários',
		'\n - ' +
			formValues.saleOperationRoleIds
				.map(
					(op_id: number) =>
						dropdownSaleOperationRole.find(u => u.id === op_id)?.description
				) // Extrai o campo `description`
				.join('\n - '),
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Quantidade de materiais',
		formValues?.materialCount,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Materiais',
		'\n - ' +
			formValues?.materialSelections
				.map(
					(m: { materialId: number; quantity: any }) =>
						`${
							dropdownMaterial.find(u => u.id === m.materialId)?.description
						} (${m.quantity})`
				)
				.join('\n - '),
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Expectativa do cliente',
		formValues?.customerExpectation,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Data prevista de embarque',
		formValues?.expectedEmbarkationDate.split('-').reverse().join('/'),
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Regime de embarque',
		dropdownEmbarkationRegime.find(u => u.id === formValues.embarkationRegime)
			?.description,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Regime de embarque personalizado (dias)',
		formValues?.embarkationPeriod ?? '',
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Quantidade de embarques',
		formValues?.numberOfBoarding,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Observações',
		formValues?.observation,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	y = addFieldToPDF(
		doc,
		'Status do projeto',
		dropdownBoardingStatus.find(u => u.id === formValues.boardingStatus)
			?.description,
		pageWidth,
		y,
		lineHeight,
		marginX,
		pageHeight
	);

	if (images.length > 0) {
		const marginY = 10; // Espaçamento inicial para cada página
		const maxWidth = pageWidth - 2 * marginX; // Largura máxima da imagem (com margens)

		// Adicionar um título para a seção de imagens
		y += 10;
		if (y + 10 > pageHeight) {
			doc.addPage();
			y = marginY;
		}
		doc.setFont('helvetica', 'bold');
		doc.setFontSize(14);
		doc.text('Imagens:', marginX, y);
		y += 10;

		// Processar e adicionar cada imagem
		for (const image of images) {
			// Adicionar o nome da imagem
			doc.setFont('helvetica', 'normal');
			doc.setFontSize(12);

			const imageName = image.name || '';
			doc.text(`- ${imageName}`, marginX, y);

			y += 8; // Espaço para o nome da imagem

			// Criar uma nova imagem HTML para cálculo de proporções
			const img = new Image();
			img.src = image.contentBase64;

			// Garantir que a imagem seja carregada antes de continuar
			await new Promise<void>(resolve => {
				img.onload = () => {
					// Calcular proporção
					const aspectRatio = img.width / img.height;
					let imageWidth = maxWidth;
					let imageHeight = maxWidth / aspectRatio;

					// Quebrar para nova página se não houver espaço suficiente
					if (y + imageHeight > pageHeight) {
						doc.addPage();
						y = marginY;
					}

					// Adicionar a imagem ao PDF
					doc.addImage(
						image.contentBase64,
						'JPEG',
						marginX,
						y,
						imageWidth,
						imageHeight
					);
					y += imageHeight + 10; // Adicionar espaço entre as imagens

					resolve();
				};
			});
		}
	}

	// Salva o arquivo como PDF
	const projectNumber = formValues.projectNumber || 'unknown';
	doc.save(`WO ${projectNumber}.pdf`);
};
