import React from 'react';
import { Grid, Typography, IconButton, Box, Paper } from '@mui/material';
import { AddPhotoAlternate, Delete } from '@mui/icons-material';
import { IImageAction } from '../services/apiService';

interface IImageBackend {
	name: string;
	contentBase64: string;
}

interface ImageUploaderProps {
	images: IImageBackend[];
	onImagesChange: (images: IImageBackend[]) => void;
}


interface ImageUploaderProps {
	images: IImageBackend[];
	onImagesChange: (images: IImageBackend[]) => void;
	setImagesAction: React.Dispatch<React.SetStateAction<IImageAction[]>>;
}

const ImageUploaderEditProject: React.FC<ImageUploaderProps> = ({
	images,
	onImagesChange,
	setImagesAction,
}) => {
	const handleFileChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const files = event.target.files;
		if (!files) return;

		const imagePromises = Array.from(files).map(file => {
			return new Promise<IImageBackend>((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => {
					resolve({
						name: file.name,
						contentBase64: reader.result as string,
					});
				};
				reader.onerror = error => reject(error);
				reader.readAsDataURL(file);
			});
		});

		try {
			const newImages = await Promise.all(imagePromises);
			const uniqueImages = [...images, ...newImages];
			onImagesChange(uniqueImages);

			// Adicionar as imagens ao estado imagesAction com ação "add"
			newImages.forEach(image => {
				setImagesAction(prev => [...prev, { image, action: 'add' }]);
			});
		} catch (error) {
			console.error('Erro ao carregar imagens:', error);
		}
	};

	const handleRemoveImage = (image: IImageBackend) => {
		const updatedImages = images.filter(img => img.name !== image.name);
		onImagesChange(updatedImages);

		// Adicionar a ação "remove" ao estado imagesAction
		setImagesAction(prev => [...prev, { image, action: 'remove' }]);
	};

	function getValidImageSrc(contentBase64?: string) {
		if (!contentBase64) {
			return '';
		}
		return contentBase64.startsWith('data:image')
			? contentBase64
			: `data:image/jpeg;base64,${contentBase64}`;
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h6" gutterBottom>
					Upload de Imagens
				</Typography>
				<Box display="flex" alignItems="center" gap={2}>
					<input
						accept="image/*"
						id="upload-images"
						type="file"
						multiple
						style={{ display: 'none' }}
						onChange={handleFileChange}
					/>
					<label htmlFor="upload-images">
						<IconButton
							component="span"
							color="primary"
							aria-label="Upload imagens"
							sx={{
								border: '1px solid',
								padding: 2,
								borderRadius: 2,
							}}
						>
							<AddPhotoAlternate />
						</IconButton>
					</label>
					<Typography variant="body2" color="textSecondary">
						Clique no ícone para adicionar imagens.
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					{images.map((image, index) => (
						<Grid item xs={12} sm={4} md={3} key={index}>
							<Paper
								sx={{
									position: 'relative',
									overflow: 'hidden',
									borderRadius: 2,
								}}
							>
								<Box
									component="img"
									src={getValidImageSrc(image.contentBase64)}
									alt={`preview-${index}`}
									sx={{
										width: '100%',
										height: '150px',
										objectFit: 'cover',
									}}
								/>
								<IconButton
									size="small"
									onClick={() => handleRemoveImage(image)}
									sx={{
										position: 'absolute',
										top: 5,
										right: 5,
										backgroundColor: 'rgba(0, 0, 0, 0.5)',
										color: 'white',
									}}
								>
									<Delete />
								</IconButton>
							</Paper>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ImageUploaderEditProject;