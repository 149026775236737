import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
	TextField,
	Button,
	Grid,
	MenuItem,
	Select,
	InputLabel,
	FormControl,
	Typography,
	makeStyles,
	OutlinedInput,
	Chip,
	SelectChangeEvent,
	Paper,
	FormHelperText,
	IconButton,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import {
	apiCreateProject,
	apiDeleteProject,
	apiGetAllBoardingStatus,
	apiGetAllContractCategory,
	apiGetAllEmbarkationRegime,
	apiGetAllMaterial,
	apiGetAllPlatformsClients,
	apiGetAllProjectClassification,
	apiGetAllProjectNumber,
	apiGetAllProjectScope,
	apiGetAllProjectScopeDetais,
	apiGetAllShift,
	apiGetAllUsers,
	apiGetAllWorker,
	apiGetFullProjectByProjectNumber,
	apiGetLastProjectNumber,
	apiGetSaleOperationRole,
	apiUpdateProject,
	IBoardingStatusBackEnd,
	IClientBackEnd,
	IContractCategoryBackEnd,
	IEmbarkationRegimeBackEnd,
	IFullProjectBackend,
	IImageAction,
	IImageOutputBackend,
	IImageOutputS3Backend,
	IMaterialBackEnd,
	IOperationRoleBackEnd,
	IPlatformBackEnd,
	IProjectClassificationBackEnd,
	IProjectFormBackend,
	IProjectNumberBackEnd,
	IProjectScopeBackEnd,
	IProjectScopeDetaisBackEnd,
	ISaleOperationRoleBackEnd,
	IUserBackEnd,
	IWorkerBackEnd,
} from '../../services/apiService';
import { handleDownloadPDF } from '../../services/handleDownloadPDF';
import internal from 'stream';
import { removeDuplicatesByIdUtil } from '../../utils/clientsBackEnd';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { green } from '@mui/material/colors';
import { existInVisibilityRoleInLocalStorage } from '../../utils/existInVisibilityRoleInLocalStorage';
import { Download } from '@mui/icons-material';
import ImageUploaderEditProject from '../ImageUploaderEditProject';
import { MoonLoader } from 'react-spinners';
import { getProjectBackend } from '../../services/getProjectBackend';
import { updateProjectBackend } from '../../services/updateProjectBackend';

export default function ProjectEdit() {
	const [dropdownClient, setDropdownClient] = useState<IClientBackEnd[]>([]);
	const [dropdownPlatform, setDropdownPlatform] = useState<IPlatformBackEnd[]>(
		[]
	);
	const [dropdownUser, setDropdownUser] = useState<IUserBackEnd[]>([]);
	const [dropdownUserFiltered, setDropdownUserFiltered] = useState<
		IUserBackEnd[]
	>([]);
	const [dropdownContractCategory, setDropdownContractCategory] = useState<
		IContractCategoryBackEnd[]
	>([]);
	const [dropdownShift, setDropdownShift] = useState<
		IContractCategoryBackEnd[]
	>([]);
	const [dropdownProjectClassification, setDropdownProjectClassification] =
		useState<IProjectClassificationBackEnd[]>([]);
	const [dropdownProjectScope, setDropdownProjectScope] = useState<
		IProjectScopeBackEnd[]
	>([]);
	const [dropdownProjectScopeDetais, setDropdownProjectScopeDetais] = useState<
		IProjectScopeDetaisBackEnd[]
	>([]);
	const [selectedClientId, setSelectedClientId] = useState<number>(0);
	const [selectedProjectScopeId, setSelectedProjectScopeId] =
		useState<number>(0);
	const [filteredPlatforms, setFilteredPlatforms] = useState<
		IPlatformBackEnd[]
	>([]);
	// const [filteredScopeDetais, setFilteredScopeDetais] = useState<
	// 	IProjectScopeDetaisBackEnd[]
	// >([]);
	const [dropdownSaleOperationRole, setDropdownSaleOperationRole] = useState<
		ISaleOperationRoleBackEnd[]
	>([]);
	const [materialMultipleSelectOptions, setMaterialMultipleSelectOptions] =
		useState<IMaterialBackEnd[]>([]);
	const [dropdownEmbarkationRegime, setDropdownEmbarkationRegime] = useState<
		IEmbarkationRegimeBackEnd[]
	>([]);
	const [dropdownBoardingStatus, setDropdownBoardingStatus] = useState<
		IBoardingStatusBackEnd[]
	>([]);
	const [teamSize, setTeamSize] = useState<number>(0);
	const [
		selectedSaleOperationRoleIdsOptions,
		setSaleSelectedOperationRoleIdsOptions,
	] = useState<number[]>([]);
	const [lastProjectNumber, setLastProjectNumber] = useState<number>(0);
	const [saleOperationRoleDropdowns, setSaleOperationRoleDropdowns] = useState<
		number[]
	>([]);
	const [scopeCount, setScopeCount] = useState<number>(0);
	const [projectScopeDropdowns, setProjectScopeDropdowns] = useState<number[]>(
		[]
	);
	const [filteredScopeDetais, setFilteredScopeDetais] = useState<
		IProjectScopeDetaisBackEnd[][]
	>([]);
	const [selectedProjectNumber, setSelectedProjectNumber] = useState<
		number | ''
	>('');
	const [dropdownProjectNumber, setDropdownProjectNumber] = useState<
		IProjectNumberBackEnd[]
	>([]);
	const [selectedProjec, setSelectedProjec] = useState<IFullProjectBackend[]>(
		[]
	);
	const [selectedProjecId, setSelectedProjecId] = useState<number>(0);
	const [materialCount, setMaterialCount] = useState<number>(0);
	const [materialDropdowns, setMaterialDropdowns] = useState<number[]>([]);
	const [hideUpdateProjectButton, setHideUpdateProjectButton] = useState(false);
	const [hideDeleteProjectButton, setHideDeleteProjectButton] = useState(false);
	const [fullProject, setFullProject] = useState<IFullProjectBackend | null>(
		null
	);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [imagesAction, setImagesAction] = useState<IImageAction[]>([]);
	const [imagesIdFullProject, setImagesIdFullProject] = useState<
		IImageOutputBackend[]
	>([]);

	const handleScopeCountChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const count = parseInt(event.target.value, 10);
		setScopeCount(count);
		formik.setFieldValue('scopeCount', count);
	};

	const handleTeamSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const size = parseInt(event.target.value, 10);
		setTeamSize(size);
		formik.setFieldValue('teamSize', size);
	};

	useEffect(() => {
		const updatedDropdowns = Array.from(
			{ length: scopeCount },
			(_, index) => index
		);
		setProjectScopeDropdowns(updatedDropdowns);
	}, [scopeCount]);

	useEffect(() => {
		const updatedDropdowns = Array.from(
			{ length: teamSize },
			(_, index) => index
		);
		setSaleOperationRoleDropdowns(updatedDropdowns);
	}, [teamSize]);

	useEffect(() => {
		const updatedDropdowns = Array.from(
			{ length: materialCount },
			(_, index) => index
		);
		setMaterialDropdowns(updatedDropdowns);
	}, [materialCount]);

	useEffect(() => {
		setHideUpdateProjectButton(
			existInVisibilityRoleInLocalStorage(
				'hide update project button (show/edit project)'
			)
		);

		setHideDeleteProjectButton(
			existInVisibilityRoleInLocalStorage(
				'hide delete project button (show/edit project)'
			)
		);

		const fetchDropdownData = async () => {
			try {
				const backEndAllProjectNumber = await apiGetAllProjectNumber();
				setDropdownProjectNumber(backEndAllProjectNumber);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados dos números dos projetos.'
				);
			}

			try {
				const backEndAllPlatformsClients = await apiGetAllPlatformsClients();
				const clients = backEndAllPlatformsClients.map(obj => obj.client);
				const clientsUnique = removeDuplicatesByIdUtil(clients);
				setDropdownClient(clientsUnique);
				setDropdownPlatform(backEndAllPlatformsClients);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados de clientes e plataformas.'
				);
			}

			try {
				const backEndAllUser = await apiGetAllUsers();
				setDropdownUser(backEndAllUser);
			} catch (error) {
				toast.error('ERROR: falha ao carregar os dados de coordenadores.');
			}

			try {
				const backEndAllContractCategory = await apiGetAllContractCategory();
				setDropdownContractCategory(backEndAllContractCategory);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados de categorias de contrato.'
				);
			}

			try {
				const backEndAllShift = await apiGetAllShift();
				setDropdownShift(backEndAllShift);
			} catch (error) {
				toast.error('ERROR: falha ao carregar os dados de turnos.');
			}

			try {
				const backEndAllProjectClassification =
					await apiGetAllProjectClassification();
				setDropdownProjectClassification(backEndAllProjectClassification);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados de classificação de projeto.'
				);
			}

			try {
				const backEndAllProjectScope = await apiGetAllProjectScope();
				setDropdownProjectScope(backEndAllProjectScope);
			} catch (error) {
				toast.error('ERROR: falha ao carregar os dados de escopo de projeto.');
			}

			try {
				const backEndAllProjectScopeDetais =
					await apiGetAllProjectScopeDetais();
				setDropdownProjectScopeDetais(backEndAllProjectScopeDetais);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados de escopo de projeto (SOW detais).'
				);
			}

			try {
				const backEndAllSaleOperationRole = await apiGetSaleOperationRole();
				setDropdownSaleOperationRole(backEndAllSaleOperationRole);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados de perfil dos funcionários.'
				);
			}

			try {
				const backEndAllMaterial = await apiGetAllMaterial();
				setMaterialMultipleSelectOptions(backEndAllMaterial);
			} catch (error) {
				toast.error('ERROR: falha ao carregar os dados de materiais.');
			}

			try {
				const backEndAllEmbarkationRegime = await apiGetAllEmbarkationRegime();
				setDropdownEmbarkationRegime(backEndAllEmbarkationRegime);
			} catch (error) {
				toast.error('ERROR: falha ao carregar os dados de regime de embarque.');
			}

			try {
				const backEndAllBoardingStatus = await apiGetAllBoardingStatus();
				setDropdownBoardingStatus(backEndAllBoardingStatus);
			} catch (error) {
				toast.error('ERROR: falha ao carregar os dados de status do embarque.');
			}
		};

		fetchDropdownData();
	}, []);

	useEffect(() => {
		if (selectedClientId) {
			const filteredPlatform = dropdownPlatform.filter(
				platform => platform.client.id === selectedClientId
			);
			setFilteredPlatforms(filteredPlatform);

			const userIds = filteredPlatform.map(item => item.client.user_id);
			const filteredUser = dropdownUser.filter(user =>
				userIds.includes(user.id)
			);
			setDropdownUserFiltered(filteredUser);
		} else {
			setFilteredPlatforms([]);
			setDropdownUserFiltered([]);
		}
	}, [selectedClientId]);

	const getValidationSchema = () => {
		return yup.object({
			projectNumber: yup
				.number()
				.required('O número do projeto é obrigatório')
				.integer()
				.positive(),
			purchaseOrder: yup
				.string()
				.required('A PO é obrigatório')
				.max(1000, 'Este campo pode ter no máximo 1000 caracteres'),
			proposal: yup
				.string()
				.required('A proposta é obrigatória')
				.max(1000, 'Este campo pode ter no máximo 1000 caracteres'),
			expectedEmbarkationDate: yup
				.date()
				.required('A data de embarcação esperada é obrigatória'),
			projectName: yup
				.string()
				.required('O nome do projeto é obrigatório')
				.max(255, 'O nome do projeto deve ter no máximo 255 caracteres'),
			clientId: yup.string().required('O cliente é obrigatório'),
			platformId: yup.string().required('A plataforma é obrigatória'),
			userId: yup.string().required('O coordenador é obrigatório'),
			projectClassificationId: yup
				.string()
				.required('A classificação do projeto é obrigatória'),
			contractCategoryId: yup
				.string()
				.required('A categoria do projeto é obrigatória'),
			shiftId: yup.string().required('O turno do projeto é obrigatório'),
			scopeObservations: yup
				.string()
				.max(1000, 'Este campo pode ter no máximo 1000 caracteres'),
			teamQuantity: yup
				.number()
				.required('A quantidade de equipes é obrigatório')
				.integer()
				.positive(),
			teamSize: yup
				.number()
				.required('O número de pessoas na equipe é obrigatório')
				.integer()
				.positive(),
			saleOperationRoleIds: yup
				.array()
				.of(yup.string().required('Seleção obrigatória'))
				.min(1, 'Pelo menos uma opção deve ser selecionada'),
			materialSelections: yup.array().of(
				yup.object({
					materialId: yup.string().required('Seleção de material obrigatória'),
					quantity: yup
						.number()
						.required('Quantidade é obrigatória')
						.integer()
						.positive(),
				})
			),
			customerExpectation: yup
				.string()
				.max(1000, 'Este campo pode ter no máximo 1000 caracteres'),
			embarkationPeriod: yup
				.number()
				.when('embarkationRegime', (embarkationRegime, schema) => {
					const isDisabled = handleDisableEmbarkationPeriod();
					return !isDisabled
						? schema
								.required('O período de embarque é obrigatório')
								.integer()
								.positive()
						: schema.nullable();
				}),
			embarkationRegime: yup
				.string()
				.required('O regime de embarque é obrigatório'),
			numberOfBoarding: yup
				.number()
				.required('A quantidade de embarque é obrigatório')
				.integer()
				.positive(),
			observation: yup
				.string()
				.max(1000, 'Este campo pode ter no máximo 1000 caracteres'),
			boardingStatus: yup
				.string()
				.required('O status do projeto é obrigatório'),
			projectScopeIds: yup
				.array()
				.of(
					yup.object({
						projectScopeId: yup.number().required('Escopo SOW é obrigatório.'),
						projectScopeDetaisId: yup
							.number()
							.required('Escopo SOW Detais é obrigatório.'),
					})
				)
				.required(),
			materialCount: yup
				.number()
				.required('A quantidade de materiais é obrigatória')
				.integer()
				.positive(),
		});
	};

	const formik = useFormik({
		initialValues: {
			projectNumber: '',
			projectName: '',
			userId: '',
			clientId: '',
			platformId: '',
			purchaseOrder: '',
			proposal: '',
			projectClassificationId: '',
			contractCategoryId: '',
			scopeObservations: '',
			teamQuantity: '',
			teamSize: '',
			shiftId: '',
			customerExpectation: '',
			expectedEmbarkationDate: '',
			embarkationPeriod: null, // Alterado de null para ''
			embarkationRegime: '',
			numberOfBoarding: '',
			observation: '',
			boardingStatus: '',
			saleOperationRoleIds: [],
			projectScopeIds: [{ projectScopeId: '', projectScopeDetaisId: '' }],
			scopeCount: 0,
			materialCount: 0,
			materialSelections: [{ materialId: '', quantity: '' }],
			images: [],
		},
		validationSchema: getValidationSchema(),
		onSubmit: async values => {
			const {
				scopeCount,
				projectScopeIds,
				materialCount,
				materialSelections,
				...backendValues
			} = values as any;
			setIsSubmitting(true); // Ativa o spinner

			// Limitar a quantidade de elementos enviados com base em scopeCount e materialCount
			const limitedProjectScopeIds = projectScopeIds.slice(0, scopeCount);
			const limitedMaterialSelections = materialSelections.slice(
				0,
				materialCount
			);

			const transformedValues = {
				...backendValues,
				projectScopeIds: limitedProjectScopeIds,
				materials: limitedMaterialSelections,
			};

			setSaleOperationRoleDropdowns([]);

			await updateProjectBackend({
				selectedProjecId,
				transformedValues,
				setIsSubmitting,
				imagesAction,
				setImagesAction,
				imagesIdFullProject,
				setImagesIdFullProject,
			});
		},
	});

	useEffect(() => {
		const newFilteredScopeDetais = formik.values.projectScopeIds.map(
			scopeIdObj => {
				return dropdownProjectScopeDetais.filter(
					detail =>
						detail.project_scope_id === Number(scopeIdObj.projectScopeId)
				);
			}
		);
		setFilteredScopeDetais(newFilteredScopeDetais);
	}, [formik.values.projectScopeIds]);

	useEffect(() => {
		setImagesAction([]);
		const fetchData = async () => {
			await getProjectBackend({
				formik,
				setFullProject,
				setSelectedProjecId,
				setSelectedClientId,
				setScopeCount,
				setTeamSize,
				setMaterialCount,
				setIsSubmitting,
				setImagesIdFullProject,
			});
		};

		fetchData();
	}, [formik.values.projectNumber]);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	};

	const handleDisableEmbarkationPeriod = (): boolean => {
		if (formik.values.embarkationRegime === '') {
			return true;
		}
		return (
			dropdownEmbarkationRegime.filter(
				regime => regime.id === +formik.values.embarkationRegime
			)[0]?.description !== 'personalizado (dias)'
		);
	};

	const handleDelete = async () => {
		setIsSubmitting(true);
		try {
			await apiDeleteProject(selectedProjecId);
			formik.resetForm();
			setImagesAction([]);
			try {
				const backEndAllProjectNumber = await apiGetAllProjectNumber();
				setDropdownProjectNumber(backEndAllProjectNumber);
			} catch (error) {
				toast.error('ERROR: falha ao atualizar os números dos projetos.');
			}
			setSelectedProjecId(0);
			toast.success('Projeto deletado com sucesso!');
		} catch (error) {
			toast.error('Erro ao deletar o Projeto.');
		}
		setIsSubmitting(false);
	};

	return (
		<Paper elevation={5} style={{ padding: 20 }}>
			<Typography variant="h5" component="h2" style={{ marginBottom: '20px' }}>
				Projeto
			</Typography>

			<form onSubmit={formik.handleSubmit} onKeyDown={handleKeyDown}>
				<Grid
					container
					spacing={2}
					style={{
						display: 'flex',
						alignItems: 'center',
						marginBottom: '20px',
					}}
				>
					<Grid item style={{ flexGrow: 1, maxWidth: '235px' }}>
						<FormControl
							fullWidth
							error={
								formik.touched.projectNumber &&
								Boolean(formik.errors.projectNumber)
							}
						>
							<InputLabel id="projectNumber-id-label">
								Número do projeto
							</InputLabel>
							<Select
								labelId="projectNumber-id-label"
								id="projectNumber"
								name="projectNumber"
								value={formik.values.projectNumber}
								onChange={event => {
									const selectedValue = event.target.value; // Obtém o valor selecionado
									formik.resetForm(); // Reseta o formulário
									formik.setFieldValue('projectNumber', selectedValue);
								}}
								label="Número do projeto"
								disabled={isSubmitting}
							>
								{dropdownProjectNumber
									.sort((a, b) => a.project_number - b.project_number)
									.map(projectNumber => (
										<MenuItem
											key={projectNumber.id}
											value={projectNumber.project_number}
										>
											{projectNumber.project_number}
										</MenuItem>
									))}
							</Select>
							{formik.touched.projectNumber && formik.errors.projectNumber ? (
								<FormHelperText>{formik.errors.projectNumber}</FormHelperText>
							) : null}
						</FormControl>
					</Grid>
					{!hideUpdateProjectButton && (
						<Grid item>
							<Button
								color="primary"
								variant="contained"
								type="submit"
								disabled={isSubmitting}
							>
								Atualizar
							</Button>
						</Grid>
					)}
					{!hideDeleteProjectButton && (
						<Grid item>
							<Button
								variant="contained"
								onClick={handleDelete}
								sx={{
									backgroundColor: 'red',
									'&:hover': {
										backgroundColor: 'darkred',
									},
									color: 'white',
								}}
								disabled={isSubmitting}
							>
								Deletar
							</Button>
						</Grid>
					)}
					<Grid item>
						<IconButton
							onClick={() => handleDownloadPDF(formik.values, fullProject)}
							style={{
								backgroundColor: '#007eb8',
								color: 'white',
								width: '30px',
								height: '30px',
								borderRadius: '4px',
							}}
						>
							<Download />
						</IconButton>
					</Grid>
					<Grid item>
						{isSubmitting && (
							<div style={{ marginLeft: '15px' }}>
								<MoonLoader size={30} />
							</div>
						)}
					</Grid>
				</Grid>

				<Grid container spacing={3} direction="column">
					<Grid item xs={12}>
						<TextField
							fullWidth
							id="purchaseOrder"
							name="purchaseOrder"
							label="PO"
							multiline
							rows={4}
							inputProps={{ maxLength: 1000 }}
							value={formik.values.purchaseOrder}
							onChange={formik.handleChange}
							error={
								formik.touched.purchaseOrder &&
								Boolean(formik.errors.purchaseOrder)
							}
							helperText={
								formik.touched.purchaseOrder && formik.errors.purchaseOrder
							}
							style={{ width: '100%', maxWidth: '1000px' }}
						/>
					</Grid>
					<Grid item container direction="row" spacing={3}>
						<Grid item xs={12} sm={4}>
							<TextField
								id="projectName"
								name="projectName"
								label="Nome do Projeto"
								value={formik.values.projectName}
								onChange={formik.handleChange}
								error={
									formik.touched.projectName &&
									Boolean(formik.errors.projectName)
								}
								helperText={
									formik.touched.projectName && formik.errors.projectName
								}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							id="proposal"
							name="proposal"
							label="Proposta"
							multiline
							rows={4}
							inputProps={{ maxLength: 1000 }}
							value={formik.values.proposal}
							onChange={formik.handleChange}
							error={formik.touched.proposal && Boolean(formik.errors.proposal)}
							helperText={formik.touched.proposal && formik.errors.proposal}
							style={{ width: '100%', maxWidth: '1000px' }}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<FormControl fullWidth>
							<InputLabel id="client-id-label">Cliente</InputLabel>
							<Select
								labelId="client-id-label"
								id="clientId"
								name="clientId"
								value={formik.values.clientId}
								onChange={e => {
									formik.setFieldValue('platformId', '');
									formik.handleChange(e);
									setSelectedClientId(+e.target.value);
								}}
								error={
									formik.touched.clientId && Boolean(formik.errors.clientId)
								}
								label="Cliente"
								style={{ width: '235px' }}
							>
								{dropdownClient
									.sort((a, b) => a.client_name.localeCompare(b.client_name))
									.map(client => (
										<MenuItem key={client.id} value={client.id}>
											{client.client_name}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item container direction="row" spacing={3}>
						<Grid item xs={12} sm={4}>
							<FormControl fullWidth>
								<InputLabel id="platform-id-label">Plataforma</InputLabel>
								<Select
									labelId="platform-id-label"
									id="platformId"
									name="platformId"
									value={formik.values.platformId}
									onChange={formik.handleChange}
									error={
										formik.touched.platformId &&
										Boolean(formik.errors.platformId)
									}
									label="Plataforma"
									style={{ width: '100%', maxWidth: '235px' }}
								>
									{filteredPlatforms
										.sort((a, b) =>
											a.platform_name.localeCompare(b.platform_name)
										)
										.map(platform => (
											<MenuItem key={platform.id} value={platform.id}>
												{platform.platform_name}
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControl fullWidth>
								<InputLabel id="contract-category-id-label">
									Categoria do Contrato
								</InputLabel>
								<Select
									labelId="contract-category-id-label"
									id="contractCategoryId"
									name="contractCategoryId"
									value={formik.values.contractCategoryId}
									onChange={formik.handleChange}
									error={
										formik.touched.contractCategoryId &&
										Boolean(formik.errors.contractCategoryId)
									}
									label="Categoria do Contrato"
									style={{ width: '100%', maxWidth: '235px' }}
								>
									{dropdownContractCategory
										.sort((a, b) => a.description.localeCompare(b.description))
										.map(contract_category => (
											<MenuItem
												key={contract_category.id}
												value={contract_category.id}
											>
												{contract_category.description}
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl fullWidth>
							<InputLabel id="userId-label">Coordenador responsável</InputLabel>
							<Select
								labelId="userId-label"
								id="userId"
								name="userId"
								value={formik.values.userId}
								onChange={formik.handleChange}
								error={formik.touched.userId && Boolean(formik.errors.userId)}
								label="Coordenador responsável"
								style={{ width: '235px' }}
							>
								{dropdownUserFiltered
									.filter(user => user.coordinator)
									.map(coordinator => (
										<MenuItem key={coordinator.id} value={coordinator.id}>
											{coordinator.username}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="scopeCount"
							name="scopeCount"
							label="Quantidade de escopos"
							type="number"
							InputProps={{ inputProps: { min: 1 } }}
							value={formik.values.scopeCount}
							onChange={handleScopeCountChange}
							error={
								formik.touched.scopeCount && Boolean(formik.errors.scopeCount)
							}
							helperText={formik.touched.scopeCount && formik.errors.scopeCount}
							style={{ width: '100%', maxWidth: '250px' }}
						/>
					</Grid>
					{projectScopeDropdowns.map((_, index) => (
						<Grid item xs={12} key={index}>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={4}>
									<FormControl fullWidth>
										<InputLabel id={`project-scope-${index}-label`}>
											Escopo do Projeto (SOW) {index + 1}
										</InputLabel>
										<Select
											labelId={`project-scope-${index}-label`}
											id={`projectScopeIds[${index}].projectScopeId`}
											name={`projectScopeIds[${index}].projectScopeId`}
											value={
												formik.values.projectScopeIds[index]?.projectScopeId ||
												''
											}
											onChange={formik.handleChange}
											error={
												formik.touched.projectScopeIds?.[index]
													?.projectScopeId &&
												Boolean(
													formik.errors.projectScopeIds?.[index] &&
														typeof formik.errors.projectScopeIds[index] ===
															'object' &&
														(formik.errors.projectScopeIds[index] as any)
															.projectScopeId
												)
											}
											label={`Escopo do Projeto (SOW) ${index + 1}`}
										>
											{dropdownProjectScope.map(project_scope => (
												<MenuItem
													key={project_scope.id}
													value={project_scope.id}
												>
													{project_scope.sow}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={4}>
									<FormControl fullWidth>
										<InputLabel id={`project-scope-detais-${index}-label`}>
											Escopo do Projeto (SOW Detais) {index + 1}
										</InputLabel>
										<Select
											labelId={`project-scope-detais-${index}-label`}
											id={`projectScopeIds[${index}].projectScopeDetaisId`}
											name={`projectScopeIds[${index}].projectScopeDetaisId`}
											value={
												formik.values.projectScopeIds[index]
													?.projectScopeDetaisId || ''
											}
											onChange={formik.handleChange}
											error={
												formik.touched.projectScopeIds &&
												Boolean(formik.errors.projectScopeIds)
											}
											label={`Escopo do Projeto (SOW Detais) ${index + 1}`}
										>
											{filteredScopeDetais[index]?.map(project_scope_detais => (
												<MenuItem
													key={project_scope_detais.id}
													value={project_scope_detais.id}
												>
													{project_scope_detais.sow_detais}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
					))}
					<Grid item xs={12} sm={4}>
						<TextField
							id="materialCount"
							name="materialCount"
							label="Quantidade de materiais"
							type="number"
							InputProps={{ inputProps: { min: 1 } }}
							value={formik.values.materialCount}
							onChange={e => {
								const count = parseInt(e.target.value, 10);
								setMaterialCount(count);
								formik.setFieldValue('materialCount', count);
							}}
							error={
								formik.touched.materialCount &&
								Boolean(formik.errors.materialCount)
							}
							helperText={
								formik.touched.materialCount && formik.errors.materialCount
							}
							style={{ width: '100%', maxWidth: '250px' }}
						/>
					</Grid>
					{materialDropdowns.map((_, index) => (
						<Grid item xs={12} key={index}>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={4}>
									<FormControl fullWidth>
										<InputLabel id={`material-${index}-label`}>
											Material {index + 1}
										</InputLabel>
										<Select
											labelId={`material-${index}-label`}
											id={`materialSelections[${index}].materialId`}
											name={`materialSelections[${index}].materialId`}
											value={
												formik.values.materialSelections[index]?.materialId ||
												''
											}
											onChange={formik.handleChange}
											error={
												formik.touched.materialSelections?.[index]
													?.materialId &&
												Boolean(
													formik.errors.materialSelections?.[index] &&
														typeof formik.errors.materialSelections[index] ===
															'object' &&
														(formik.errors.materialSelections[index] as any)
															.materialId
												)
											}
											label={`Material ${index + 1}`}
										>
											{materialMultipleSelectOptions
												.sort((a, b) =>
													a.description.localeCompare(b.description)
												)
												.map(material => (
													<MenuItem key={material.id} value={material.id}>
														{material.description}
													</MenuItem>
												))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										id={`materialSelections[${index}].quantity`}
										name={`materialSelections[${index}].quantity`}
										label={`Quantidade do Material ${index + 1}`}
										type="number"
										InputProps={{ inputProps: { min: 1 } }}
										value={
											formik.values.materialSelections[index]?.quantity || ''
										}
										onChange={formik.handleChange}
										error={
											formik.touched.materialSelections?.[index]?.quantity &&
											Boolean(
												formik.errors.materialSelections?.[index] &&
													typeof formik.errors.materialSelections[index] ===
														'object' &&
													(formik.errors.materialSelections[index] as any)
														.quantity
											)
										}
										helperText={
											formik.touched.materialSelections?.[index]?.quantity &&
											typeof formik.errors.materialSelections?.[index] ===
												'object' &&
											(formik.errors.materialSelections[index] as any).quantity
										}
										style={{ width: '100%', maxWidth: '250px' }}
									/>
								</Grid>
							</Grid>
						</Grid>
					))}
					<Grid item xs={12} sm={4}>
						<FormControl fullWidth>
							<InputLabel id="project-classification-id-label">
								Classificação do Projeto
							</InputLabel>
							<Select
								labelId="project-classification-id-label"
								id="projectClassificationId"
								name="projectClassificationId"
								value={formik.values.projectClassificationId}
								onChange={formik.handleChange}
								error={
									formik.touched.projectClassificationId &&
									Boolean(formik.errors.projectClassificationId)
								}
								label="Classificação do Projeto"
								style={{ width: '235px' }}
							>
								{dropdownProjectClassification
									.sort((a, b) => a.description.localeCompare(b.description))
									.map(project_classification => (
										<MenuItem
											key={project_classification.id}
											value={project_classification.id}
										>
											{project_classification.description}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							id="scopeObservations"
							name="scopeObservations"
							label="Observações de Escopo"
							multiline
							rows={4}
							value={formik.values.scopeObservations}
							onChange={formik.handleChange}
							error={
								formik.touched.scopeObservations &&
								Boolean(formik.errors.scopeObservations)
							}
							helperText={
								formik.touched.scopeObservations &&
								formik.errors.scopeObservations
							}
							inputProps={{ maxLength: 1000 }}
							style={{ width: '100%', maxWidth: '1000px' }}
						/>
					</Grid>
					<Grid item container direction="row" spacing={3}>
						<Grid item xs={12} sm={4}>
							<TextField
								id="teamQuantity"
								name="teamQuantity"
								label="Quantidade de equipes"
								type="number"
								InputProps={{ inputProps: { min: 1 } }}
								value={formik.values.teamQuantity}
								onChange={formik.handleChange}
								error={
									formik.touched.teamQuantity &&
									Boolean(formik.errors.teamQuantity)
								}
								helperText={
									formik.touched.teamQuantity && formik.errors.teamQuantity
								}
								style={{ width: '100%', maxWidth: '250px' }}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<FormControl fullWidth>
								<InputLabel id="shift-id-label">Turno</InputLabel>
								<Select
									labelId="shift-id-label"
									id="shiftId"
									name="shiftId"
									value={formik.values.shiftId}
									onChange={formik.handleChange}
									error={
										formik.touched.shiftId && Boolean(formik.errors.shiftId)
									}
									label="Turno"
									style={{ width: '100%', maxWidth: '235px' }}
								>
									{dropdownShift
										.sort((a, b) => a.description.localeCompare(b.description))
										.map(shift => (
											<MenuItem key={shift.id} value={shift.id}>
												{shift.description}
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="teamSize"
							name="teamSize"
							label="Número total de pessoas"
							type="number"
							InputProps={{ inputProps: { min: 1 } }}
							value={formik.values.teamSize}
							onChange={handleTeamSizeChange}
							error={formik.touched.teamSize && Boolean(formik.errors.teamSize)}
							helperText={formik.touched.teamSize && formik.errors.teamSize}
							style={{ width: '100%', maxWidth: '250px' }}
						/>
					</Grid>
					{saleOperationRoleDropdowns.map((_, index) => (
						<Grid item xs={12} key={index}>
							<FormControl fullWidth>
								<InputLabel id={`sale-operation-role-${index}-label`}>
									Perfil do Funcionário {index + 1}
								</InputLabel>
								<Select
									labelId={`sale-operation-role-${index}-label`}
									id={`saleOperationRoleIds[${index}]`}
									name={`saleOperationRoleIds[${index}]`}
									value={String(
										formik.values.saleOperationRoleIds[index] || ''
									)}
									onChange={formik.handleChange}
									error={
										formik.touched.saleOperationRoleIds &&
										Boolean(formik.errors.saleOperationRoleIds)
									}
									label={`Perfil do Funcionário ${index + 1}`}
									style={{ width: '100%', maxWidth: '1000px' }}
								>
									{dropdownSaleOperationRole
										.sort((a, b) => a.description.localeCompare(b.description))
										.map(saleOperationRole => (
											<MenuItem
												key={saleOperationRole.id}
												value={saleOperationRole.id}
											>
												{saleOperationRole.description}
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</Grid>
					))}
					<Grid item xs={12} sm={6}>
						<FormControl fullWidth>
							<InputLabel id="boarding-status-id-label">
								Status do projeto
							</InputLabel>
							<Select
								labelId="boarding-status-id-label"
								id="boardingStatus"
								name="boardingStatus"
								value={formik.values.boardingStatus}
								onChange={formik.handleChange}
								error={
									formik.touched.boardingStatus &&
									Boolean(formik.errors.boardingStatus)
								}
								label="Status do projeto"
								style={{ width: '100%', maxWidth: '235px' }}
							>
								{dropdownBoardingStatus
									.sort((a, b) => a.description.localeCompare(b.description))
									.map(boardingStatus => (
										<MenuItem key={boardingStatus.id} value={boardingStatus.id}>
											{boardingStatus.description}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							id="customerExpectation"
							name="customerExpectation"
							label="Expectativa do Cliente"
							multiline
							rows={4}
							value={formik.values.customerExpectation}
							onChange={formik.handleChange}
							error={
								formik.touched.customerExpectation &&
								Boolean(formik.errors.customerExpectation)
							}
							helperText={
								formik.touched.customerExpectation &&
								formik.errors.customerExpectation
							}
							inputProps={{ maxLength: 1000 }}
							style={{ width: '100%', maxWidth: '1000px' }}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="expectedEmbarkationDate"
							name="expectedEmbarkationDate"
							label="Data Prevista de Embarque"
							type="date"
							InputLabelProps={{ shrink: true }}
							value={formik.values.expectedEmbarkationDate}
							onChange={formik.handleChange}
							error={
								formik.touched.expectedEmbarkationDate &&
								Boolean(formik.errors.expectedEmbarkationDate)
							}
							helperText={
								formik.touched.expectedEmbarkationDate &&
								formik.errors.expectedEmbarkationDate
							}
							style={{ width: '100%', maxWidth: '235px' }}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl fullWidth>
							<InputLabel id="embarkation-regime-id-label">
								Regime de embarque
							</InputLabel>
							<Select
								labelId="embarkation-regime-id-label"
								id="embarkationRegime"
								name="embarkationRegime"
								value={formik.values.embarkationRegime}
								onChange={formik.handleChange}
								error={
									formik.touched.embarkationRegime &&
									Boolean(formik.errors.embarkationRegime)
								}
								label="Regime de embarque"
								style={{ width: '100%', maxWidth: '235px' }}
							>
								{dropdownEmbarkationRegime.map(embarkationRegime => (
									<MenuItem
										key={embarkationRegime.id}
										value={embarkationRegime.id}
									>
										{embarkationRegime.description}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							key={formik.values.embarkationPeriod}
							id="embarkationPeriod"
							name="embarkationPeriod"
							label="Regime de Embarque Personalizado (dias)"
							type="number"
							InputLabelProps={{ shrink: true }}
							InputProps={{ inputProps: { min: 1 } }}
							value={formik.values.embarkationPeriod}
							onChange={formik.handleChange}
							disabled={handleDisableEmbarkationPeriod()}
							error={
								formik.touched.embarkationPeriod &&
								Boolean(formik.errors.embarkationPeriod)
							}
							helperText={
								formik.touched.embarkationPeriod &&
								formik.errors.embarkationPeriod
							}
							style={{ width: '100%', maxWidth: '350px' }}
						/>
					</Grid>
					<Grid item container direction="row" spacing={3}>
						<Grid item xs={12} sm={4}>
							<TextField
								id="numberOfBoarding"
								name="numberOfBoarding"
								label="Quantidade de embarques"
								type="number"
								InputProps={{ inputProps: { min: 1 } }}
								value={formik.values.numberOfBoarding}
								onChange={formik.handleChange}
								error={
									formik.touched.numberOfBoarding &&
									Boolean(formik.errors.numberOfBoarding)
								}
								helperText={
									formik.touched.numberOfBoarding &&
									formik.errors.numberOfBoarding
								}
								style={{ width: '100%', maxWidth: '250px' }}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							id="observation"
							name="observation"
							label="Observação"
							multiline
							rows={4}
							value={formik.values.observation}
							onChange={formik.handleChange}
							error={
								formik.touched.observation && Boolean(formik.errors.observation)
							}
							helperText={
								formik.touched.observation && formik.errors.observation
							}
							inputProps={{ maxLength: 1000 }}
							style={{ width: '100%', maxWidth: '1000px' }}
						/>
					</Grid>
					<Grid item xs={12}>
						<ImageUploaderEditProject
							images={formik.values.images}
							onImagesChange={updatedImages =>
								formik.setFieldValue('images', updatedImages)
							}
							setImagesAction={setImagesAction}
						/>

						{formik.errors.images && (
							<Typography color="error" variant="body2">
								{formik.errors.images}
							</Typography>
						)}
					</Grid>
				</Grid>
			</form>
		</Paper>
	);
}
