import React, { useEffect, useState } from 'react';
import {
	IProjectNumberBackEnd,
	IUserBackEnd,
	apiGetAllProjectNumber,
	apiGetAllUsers,
	IBoardingBackend,
	apiGetAllBoarding,
	IClientBackEnd,
	apiGetAllBoardingLast20,
	apiGetAllClient,
	apiGetAllPlatforms,
	IPlatformOnlyBackEnd,
	apiGetBoardingsByFilters,
} from '../../services/apiService';
import {
	Button,
	Card,
	CardContent,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';
import axios from 'axios';
import { getMonthBoundaryDate } from '../../utils/getMonthBoundaryDate';

function formatDateToBrazilian(dateString: string | null): string {
	if (!dateString) {
		return '';
	}
	const [year, month, day] = dateString.split('-');
	return `${day}-${month}-${year}`;
}

interface IBoardingAssignmentProps {
	selectedCard: number | null;
	setSelectedCard: (selectedCard: number | null) => void;
	selectedWo: number | null;
	setSelectedWo: (selectedWo: number | null) => void;
	clickAssign: boolean;
}

function BoardingAssignment({
	selectedCard,
	setSelectedCard,
  selectedWo,
  setSelectedWo,
	clickAssign,
}: IBoardingAssignmentProps) {
	const [isLoading, setIsLoading] = useState(true);
	const [boarding, setBoarding] = useState<IBoardingBackend[]>([]);
	const [dropdownProjectNumber, setDropdownProjectNumber] = useState<
		IProjectNumberBackEnd[]
	>([]);
	const [filteredProjectNumbers, setFilteredProjectNumbers] = useState<
		IProjectNumberBackEnd[]
	>([]);
	const [filteredBoarding, setFilteredBoarding] = useState<IBoardingBackend[]>(
		[]
	);
	const [selectedProjectNumber, setSelectedProjectNumber] = useState<
		number | ''
	>('');
	const [startDate, setStartDate] = useState<string>(
		getMonthBoundaryDate('first')
	);
	const [endDate, setEndDate] = useState<string>(getMonthBoundaryDate('last'));
	const [dropdownPlatform, setDropdownPlatform] = useState<
		IPlatformOnlyBackEnd[]
	>([]);
	const [selectedPlatform, setSelectedPlatform] = useState<number | ''>('');
	const [dropdownClient, setDropdownClient] = useState<IClientBackEnd[]>([]);
	const [selectedClient, setSelectedClient] = useState<number | ''>('');

	useEffect(() => {
		const fetchDropdownData = async () => {
			try {
				const backEndAllProjectNumber = await apiGetAllProjectNumber();
				setDropdownProjectNumber(backEndAllProjectNumber);
				setFilteredProjectNumbers(backEndAllProjectNumber);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados dos números dos projetos.'
				);
			}
			try {
				const backEndAllPlatforms = await apiGetAllPlatforms();
				setDropdownPlatform(backEndAllPlatforms);
			} catch (error) {
				toast.error('ERROR: falha ao carregar os dados de plataformas.');
			}
			try {
				const backEndAllClient = await apiGetAllClient();
				setDropdownClient(backEndAllClient);
			} catch (error) {
				toast.error('ERROR: falha ao carregar os dados de clientes.');
			}
			try {
				const boardings = await apiGetBoardingsByFilters(
					'',
					selectedClient,
					selectedPlatform,
					selectedProjectNumber,
					startDate,
					endDate
				);
				setBoarding(boardings);
				setFilteredBoarding(boardings);
			} catch (error) {
				toast.error('ERROR: falha ao carregar os dados de embarque.');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		fetchDropdownData();
	}, []);

	const handleFilterSubmit = () => {
		const fetchBoardings = async () => {
			try {
				const boardings = await apiGetBoardingsByFilters(
					'',
					selectedClient,
					selectedPlatform,
					selectedProjectNumber,
					startDate,
					endDate
				);
				setFilteredBoarding(boardings);
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response?.status !== 404) {
						toast.error('ERROR: falha ao carregar dados de embarque.');
					} else {
						toast.warning('WARNING: nenhum embarque encontrado.');
					}
				} else {
					toast.error('ERROR: falha ao carregar dados.');
				}
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		setIsLoading(true);
		fetchBoardings();
	};

	useEffect(() => {
		handleFilterSubmit();
	}, [clickAssign]);

	const handleClear = () => {
		setSelectedPlatform('');
		setSelectedProjectNumber('');
		setSelectedClient('');
		setFilteredProjectNumbers(dropdownProjectNumber);
		setStartDate('');
		setEndDate('');
	};

	const handleCardClick = (id: number, wo: number) => {
		setSelectedCard(selectedCard === id ? null : id);
		setSelectedWo(selectedWo === wo ? null : wo);
	};

	return (
		<>
			<Grid item xs={12}>
				<Paper elevation={5} style={{ padding: 20 }}>
					<Typography
						variant="h5"
						component="h2"
						style={{ marginBottom: '20px' }}
					>
						Embarques Sistema
					</Typography>
					<Grid container spacing={3} style={{ marginBottom: '40px' }}>
						{/* Primeira linha: Data início à WO */}
						<Grid item xs={12} container spacing={3}>
							<Grid item xs={12} sm={2.7}>
								<TextField
									label="Data início (PROG)"
									type="date"
									value={startDate}
									onChange={e => setStartDate(e.target.value)}
									InputLabelProps={{
										shrink: true,
									}}
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} sm={2.7}>
								<TextField
									label="Data fim (PROG)"
									type="date"
									value={endDate}
									onChange={e => setEndDate(e.target.value)}
									InputLabelProps={{
										shrink: true,
									}}
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} sm={2}>
								<FormControl fullWidth>
									<InputLabel id="projectNumber-id-label">WO</InputLabel>
									<Select
										labelId="projectNumber-id-label"
										id="projectNumberId"
										name="projectNumberId"
										value={selectedProjectNumber}
										onChange={e => setSelectedProjectNumber(+e.target.value)}
										label="WO"
									>
										{filteredProjectNumbers
											.sort((a, b) => a.project_number - b.project_number)
											.map(projectNumber => (
												<MenuItem
													key={projectNumber.id}
													value={projectNumber.project_number}
												>
													{projectNumber.project_number}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>

						{/* Segunda linha: Cliente e Plataforma */}
						<Grid item xs={12} container spacing={3}>
							<Grid item xs={12} sm={3.1}>
								<FormControl fullWidth>
									<InputLabel id="client-id-label">Cliente</InputLabel>
									<Select
										labelId="client-id-label"
										id="clientId"
										name="clientId"
										value={selectedClient}
										onChange={e => setSelectedClient(+e.target.value)}
										label="Cliente"
										style={{ width: '100%', maxWidth: '235px' }}
									>
										{dropdownClient
											.sort((a, b) =>
												a.client_name.localeCompare(b.client_name)
											)
											.map(client => (
												<MenuItem key={client.id} value={client.id}>
													{client.client_name}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={7}>
								<FormControl fullWidth>
									<InputLabel id="platform-id-label">Plataforma</InputLabel>
									<Select
										labelId="platform-id-label"
										id="platformId"
										name="platformId"
										value={selectedPlatform}
										onChange={e => setSelectedPlatform(+e.target.value)}
										label="Plataforma"
										style={{ width: '100%', maxWidth: '600px' }}
									>
										{dropdownPlatform
											.sort((a, b) =>
												a.platform_name.localeCompare(b.platform_name)
											)
											.map(platform => (
												<MenuItem key={platform.id} value={platform.id}>
													{platform.platform_name}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>

						{/* Terceira linha: Botões */}
						<Grid item xs={12} container spacing={3}>
							<Grid item xs={12} sm={2}>
								<Button
									variant="contained"
									onClick={handleFilterSubmit}
									style={{ height: '56px', marginRight: '8px' }}
									fullWidth
								>
									Filtrar
								</Button>
							</Grid>
							<Grid item xs={12} sm={2}>
								<Button
									variant="contained"
									onClick={handleClear}
									style={{
										height: '56px',
										background: '#e5e5e5',
										color: 'black',
									}}
									fullWidth
								>
									Limpar
								</Button>
							</Grid>
						</Grid>
					</Grid>

					{isLoading ? (
						<div>
							<MoonLoader color="#36d7b7" />
						</div>
					) : (
						<Grid container spacing={0.5} direction="column">
							{' '}
							{/* Diminuir mais a distância entre os cards */}
							{filteredBoarding
								.sort((a, b) => {
									if (a.project.project_number !== b.project.project_number) {
										return a.project.project_number - b.project.project_number;
									}
									return a.boarding_number - b.boarding_number;
								})
								.map(row => (
									<Grid item xs={12} key={row.id}>
										<Card
											onClick={() => handleCardClick(row.id, row.project.project_number)}
											style={{
												cursor: 'pointer',
												border:
													selectedCard === row.id
														? '2px solid blue'
														: '1px solid grey',
												backgroundColor:
													selectedCard === row.id ? '#f0f8ff' : '#f5f5f5', // cinza leve
											}}
										>
											<CardContent>
												<Grid container spacing={2}>
													{' '}
													{/* Espaçamento uniforme entre as colunas */}
													<Grid item xs={3}>
														{' '}
														{/* Primeira coluna */}
														{/* <Typography variant="h6"> */}
														<Typography>
															WO: <strong>{row.project.project_number}</strong>{' '}
															{/* Número do projeto em negrito */}
														</Typography>
														<Typography variant="body2">
															Número embarque: {row.boarding_number}
														</Typography>
													</Grid>
													<Grid item xs={5}>
														{' '}
														{/* Aumentar o espaço horizontal da segunda coluna */}
														<Typography variant="body2">
															Embarque (PROG):{' '}
															{formatDateToBrazilian(
																row.scheduled_embarkation_date
															)}
														</Typography>
														<Typography variant="body2">
															Desembarque (PROG):{' '}
															{formatDateToBrazilian(
																row.scheduled_disembarkation_date
															)}
														</Typography>
														<Typography
															variant="body2"
															style={{ marginTop: '10px' }}
														>
															UOP:{' '}
															<strong>
																{row.project.platform.platform_name}
															</strong>
														</Typography>
													</Grid>
													<Grid item xs={4}>
														{' '}
														{/* Terceira coluna */}
														<Typography variant="body2">
															Embarque (REAL):{' '}
															{formatDateToBrazilian(
																row.actual_embarkation_date
															)}
														</Typography>
														<Typography variant="body2">
															Desembarque (REAL):{' '}
															{formatDateToBrazilian(
																row.actual_disembarkation_date
															)}
														</Typography>
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Grid>
								))}
						</Grid>
					)}
				</Paper>
			</Grid>
		</>
	);
}

export default BoardingAssignment;
