import React, { useEffect, useState } from 'react';
import {
	IProjectNumberBackEnd,
	IUserBackEnd,
	apiGetAllProjectNumber,
	apiGetAllUsers,
	IBoardingBackend,
	apiGetAllBoarding,
	IClientBackEnd,
	apiGetAllBoardingLast20,
	apiGetAllClient,
	apiGetAllPlatforms,
	IPlatformOnlyBackEnd,
	apiGetBoardingsByFilters,
	apiDeleteBoarding,
} from '../../services/apiService';
import {
	Box,
	Button,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';
import axios from 'axios';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { existInVisibilityRoleInLocalStorage } from '../../utils/existInVisibilityRoleInLocalStorage';
import { Download } from '@mui/icons-material';
import * as XLSX from 'xlsx';

function formatDateToBrazilian(dateString: string | null): string {
	if (!dateString) {
		return '';
	}
	const [year, month, day] = dateString.split('-');
	return `${day}-${month}-${year}`;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	borderColor: theme.palette.divider,
	borderWidth: 1,
	borderStyle: 'solid',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
}));

function ProjectPlanningPage() {
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);
	const [boarding, setBoarding] = useState<IBoardingBackend[]>([]);
	const [dropdownUser, setDropdownUser] = useState<IUserBackEnd[]>([]);
	const [dropdownClient, setDropdownClient] = useState<IClientBackEnd[]>([]);
	const [dropdownPlatform, setDropdownPlatform] = useState<
		IPlatformOnlyBackEnd[]
	>([]);
	const [dropdownProjectNumber, setDropdownProjectNumber] = useState<
		IProjectNumberBackEnd[]
	>([]);
	const [filteredClient, setFilteredClient] = useState<IClientBackEnd[]>([]);
	const [filteredPlatform, setFilteredPlatform] = useState<
		IPlatformOnlyBackEnd[]
	>([]);
	const [filteredPlatformByUser, setFilteredPlatformByUser] = useState<
		IPlatformOnlyBackEnd[]
	>([]);
	const [filteredPlatformByClient, setFilteredPlatformByClient] = useState<
		IPlatformOnlyBackEnd[]
	>([]);
	const [filteredProjectNumbers, setFilteredProjectNumbers] = useState<
		IProjectNumberBackEnd[]
	>([]);
	const [filteredProjectNumbersByUser, setFilteredProjectNumbersByUser] =
		useState<IProjectNumberBackEnd[]>([]);
	const [filteredProjectNumbersByClient, setFilteredProjectNumbersByClient] =
		useState<IProjectNumberBackEnd[]>([]);
	const [filteredBoarding, setFilteredBoarding] = useState<IBoardingBackend[]>(
		[]
	);
	const [selectedUser, setSelectedUser] = useState<number | ''>('');
	const [selectedClient, setSelectedClient] = useState<number | ''>('');
	const [selectedPlatform, setSelectedPlatform] = useState<number | ''>('');
	const [selectedProjectNumber, setSelectedProjectNumber] = useState<
		number | ''
	>('');
	const [hideEditBoardingButton, setHideEditBoardingButton] = useState(false);
	const [hideDeleteBoardingButton, setHideDeleteBoardingButton] =
		useState(false);
	const [startDate, setStartDate] = useState<string>('');
	const [endDate, setEndDate] = useState<string>('');
	const [isLoadingButton, setIsLoadingButton] = useState(false);

	useEffect(() => {
		setHideEditBoardingButton(
			existInVisibilityRoleInLocalStorage(
				'hide edit boarding button (planning boarding)'
			)
		);

		setHideDeleteBoardingButton(
			existInVisibilityRoleInLocalStorage(
				'hide delete boarding button (planning boarding)'
			)
		);

		const fetchDropdownData = async () => {
			try {
				const backEndAllUser = await apiGetAllUsers();
				setDropdownUser(backEndAllUser);
			} catch (error) {
				toast.error('ERROR: falha ao carregar os dados de coordenadores.');
			}
			try {
				const backEndAllClient = await apiGetAllClient();
				setDropdownClient(backEndAllClient);
				setFilteredClient(backEndAllClient);
			} catch (error) {
				toast.error('ERROR: falha ao carregar os dados de clientes.');
			}
			try {
				const backEndAllPlatforms = await apiGetAllPlatforms();
				setDropdownPlatform(backEndAllPlatforms);
				setFilteredPlatform(backEndAllPlatforms);
			} catch (error) {
				toast.error('ERROR: falha ao carregar os dados de plataformas.');
			}

			try {
				const backEndAllProjectNumber = await apiGetAllProjectNumber();
				setDropdownProjectNumber(backEndAllProjectNumber);
				setFilteredProjectNumbers(backEndAllProjectNumber);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados dos números dos projetos.'
				);
			}
			try {
				const boardings = await apiGetAllBoardingLast20();
				setBoarding(boardings);
				setFilteredBoarding(boardings);
			} catch (error) {
				toast.error('ERROR: falha ao carregar os dados de embarque.');
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		fetchDropdownData();
	}, []);

	useEffect(() => {
		setSelectedClient('');
		setSelectedPlatform('');
		setSelectedProjectNumber('');

		if (selectedUser === '') {
			setFilteredClient(dropdownClient);
			setFilteredPlatform(dropdownPlatform);
			setFilteredProjectNumbers(dropdownProjectNumber);
			return;
		}

		const filteredClientNew = dropdownClient.filter(
			client => client.user_id === selectedUser
		);
		setFilteredClient(filteredClientNew);

		const filteredPlatformNew = dropdownPlatform.filter(platform =>
			filteredClientNew.some(client => client.id === platform.client_id)
		);
		setFilteredPlatformByUser(filteredPlatformNew);
		setFilteredPlatform(filteredPlatformNew);

		const filteredProjectNumbersNew = dropdownProjectNumber.filter(
			project_number =>
				filteredPlatformNew.some(
					platform => platform.id === project_number.platform_id
				)
		);
		setFilteredProjectNumbersByUser(filteredProjectNumbersNew);
		setFilteredProjectNumbers(filteredProjectNumbersNew);
	}, [selectedUser]);

	useEffect(() => {
		setSelectedPlatform('');
		setSelectedProjectNumber('');

		if (selectedClient === '') {
			setFilteredPlatform(
				selectedUser ? filteredPlatformByUser : dropdownPlatform
			);
			setFilteredProjectNumbers(
				selectedUser ? filteredProjectNumbersByUser : dropdownProjectNumber
			);
			return;
		}

		const filteredPlatformNew = (
			selectedUser ? filteredPlatformByUser : dropdownPlatform
		).filter(platform => platform.client_id === selectedClient);
		setFilteredPlatformByClient(filteredPlatformNew);
		setFilteredPlatform(filteredPlatformNew);

		const filteredProjectNumbersNew = (
			selectedUser ? filteredProjectNumbersByUser : dropdownProjectNumber
		).filter(project_number =>
			filteredPlatformNew.some(
				platform => platform.id === project_number.platform_id
			)
		);
		setFilteredProjectNumbersByClient(filteredProjectNumbersNew);
		setFilteredProjectNumbers(filteredProjectNumbersNew);
	}, [selectedClient]);

	useEffect(() => {
		setSelectedProjectNumber('');

		if (selectedPlatform === '') {
			setFilteredProjectNumbers(
				selectedClient
					? filteredProjectNumbersByClient
					: selectedUser
					? filteredProjectNumbersByUser
					: dropdownProjectNumber
			);
			return;
		}

		const filteredProjectNumbersNew = (
			selectedClient
				? filteredProjectNumbersByClient
				: selectedUser
				? filteredProjectNumbersByUser
				: dropdownProjectNumber
		).filter(project_number => project_number.platform_id === selectedPlatform);
		setFilteredProjectNumbers(filteredProjectNumbersNew);
	}, [selectedPlatform]);

	const handleFilterSubmit = async () => {
		const fetchBoardings = async () => {
			try {
				const boardings = await apiGetBoardingsByFilters(
					selectedUser,
					selectedClient,
					selectedPlatform,
					selectedProjectNumber,
					startDate,
					endDate
				);
				setFilteredBoarding(boardings);
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response?.status !== 404) {
						toast.error('ERROR: falha ao carregar dados de embarque.');
					} else {
						toast.warning('WARNING: nenhum embarque encontrado.');
					}
				} else {
					toast.error('ERROR: falha ao carregar dados.');
				}
			}
		};
		setIsLoadingButton(true);
		await fetchBoardings();
		setIsLoadingButton(false);
	};

	const handleDelete = (boardingId: number) => {
		const fetchDeleteBoarding = async () => {
			try {
				await apiDeleteBoarding(boardingId);
				handleFilterSubmit();
				toast.success('Embarque deletado com sucesso.');
			} catch (error) {
				if (axios.isAxiosError(error)) {
					toast.error('ERROR: falha ao deletar embarque.');
				} else {
					toast.error('ERROR: erro desconhecido.');
				}
			}
		};
		fetchDeleteBoarding();
	};

	const handleClear = () => {
		setSelectedUser('');
		setSelectedClient('');
		setSelectedPlatform('');
		setSelectedProjectNumber('');
		setStartDate('');
		setEndDate('');
		setFilteredClient(dropdownClient);
		setFilteredPlatform(dropdownPlatform);
		setFilteredProjectNumbers(dropdownProjectNumber);
	};

	const handleDownloadExcel = () => {
		if (!filteredBoarding || filteredBoarding.length === 0) {
			toast.error('ERROR: Nenhum dado disponível para download.');
			return;
		}

		// Cabeçalho da tabela (primeira linha)
		const tableData: any[] = [
			[
				'WO',
				'Número embarque',
				'Cliente',
				'Sonda',
				'Escopo',
				'Número de pessoas',
				'Status do embarque',
				'Data embarque (PLAN)',
				'Data desembarque (PLAN)',
				'Data embarque (PROG)',
				'Data desembarque (PROG)',
				'Data embarque (REAL)',
				'Data desembarque (REAL)',
			],
		];

		// Preenche as linhas com base em cada row da tabela
		filteredBoarding.forEach(row => {
			tableData.push([
				row.project.project_number,
				row.boarding_number,
				row.project.client.client_name,
				row.project.platform.platform_name,
				row.project.project_scope_ids.length > 1
					? `${row.project.project_scope_ids[0]?.project_scope?.sow ?? ''}...`
					: row.project.project_scope_ids[0]?.project_scope?.sow ?? '',
				row.number_of_workers,
				row.boarding_status.description,
				formatDateToBrazilian(row.planned_embarkation_date),
				formatDateToBrazilian(row.planned_disembarkation_date),
				formatDateToBrazilian(row.scheduled_embarkation_date),
				formatDateToBrazilian(row.scheduled_disembarkation_date),
				formatDateToBrazilian(row.actual_embarkation_date),
				formatDateToBrazilian(row.actual_disembarkation_date),
			]);
		});

		// Cria o workbook (arquivo Excel) e a aba (worksheet)
		const workbook = XLSX.utils.book_new();
		const worksheet = XLSX.utils.aoa_to_sheet(tableData);

		// Adiciona a worksheet ao workbook
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Boardings Data');

		// Escreve o arquivo Excel e faz o download
		XLSX.writeFile(workbook, 'boardings_data.xlsx');
	};

	return (
		<Grid style={{ padding: 20 }}>
			<Typography variant="h5" component="h2" style={{ marginBottom: '20px' }}>
				Planejamento do Embarque
			</Typography>
			<Grid
				item
				container
				direction="row"
				spacing={3}
				style={{ marginBottom: '20px' }}
			>
				<Grid item xs={12} sm={2} style={{ maxWidth: '175px' }}>
					<FormControl fullWidth>
						<TextField
							label="Data início"
							type="date"
							value={startDate}
							onChange={e => setStartDate(e.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={2} style={{ maxWidth: '175px' }}>
					<FormControl fullWidth>
						<TextField
							label="Data fim"
							type="date"
							value={endDate}
							onChange={e => setEndDate(e.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={2}>
					<FormControl fullWidth>
						<InputLabel id="user-id-label">Coordenador</InputLabel>
						<Select
							labelId="user-id-label"
							id="userId"
							name="userId"
							value={selectedUser}
							onChange={e => setSelectedUser(+e.target.value)}
							label="Coordenador"
							style={{ width: '100%', maxWidth: '235px' }}
						>
							{dropdownUser
								.sort((a, b) => a.username.localeCompare(b.username))
								.filter(user => user.coordinator)
								.map(user => (
									<MenuItem key={user.id} value={user.id}>
										{user.username}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={2}>
					<FormControl fullWidth>
						<InputLabel id="client-id-label">Cliente</InputLabel>
						<Select
							labelId="client-id-label"
							id="clientId"
							name="clientId"
							value={selectedClient}
							onChange={e => setSelectedClient(+e.target.value)}
							label="Cliente"
							style={{ width: '100%', maxWidth: '235px' }}
						>
							{filteredClient
								.sort((a, b) => a.client_name.localeCompare(b.client_name))
								.map(client => (
									<MenuItem key={client.id} value={client.id}>
										{client.client_name}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={2}>
					<FormControl fullWidth>
						<InputLabel id="platform-id-label">Plataforma</InputLabel>
						<Select
							labelId="platform-id-label"
							id="platformId"
							name="platformId"
							value={selectedPlatform}
							onChange={e => setSelectedPlatform(+e.target.value)}
							label="Plataforma"
							style={{ width: '100%', maxWidth: '235px' }}
						>
							{filteredPlatform
								.sort((a, b) => a.platform_name.localeCompare(b.platform_name))
								.map(platform => (
									<MenuItem key={platform.id} value={platform.id}>
										{platform.platform_name}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={2}>
					<FormControl fullWidth>
						<InputLabel id="projectNumber-id-label">
							Número do projeto
						</InputLabel>
						<Select
							labelId="projectNumber-id-label"
							id="projectNumberId"
							name="projectNumberId"
							value={selectedProjectNumber}
							onChange={e => setSelectedProjectNumber(+e.target.value)}
							label="Número do projeto"
							style={{ width: '100%', maxWidth: '235px' }}
						>
							{filteredProjectNumbers
								.sort((a, b) => a.project_number - b.project_number)
								.map(projectNumber => (
									<MenuItem
										key={projectNumber.id}
										value={projectNumber.project_number}
									>
										{projectNumber.project_number}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={2}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Button
							variant="contained"
							onClick={handleFilterSubmit}
							style={{ height: '56px' }}
							disabled={isLoadingButton}
						>
							Filtrar
						</Button>
						<Button
							variant="contained"
							onClick={handleClear}
							style={{
								height: '56px',
								marginLeft: '23px',
								background: '#e5e5e5',
								color: 'black',
								marginRight: '23px'
							}}
							disabled={isLoadingButton}
						>
							Limpar
						</Button>
						<IconButton
							onClick={handleDownloadExcel}
							style={{
								backgroundColor: '#007eb8',
								color: 'white',
								width: '30px',
								height: '30px',
								borderRadius: '4px',
								// marginLeft:'15px'
							}}
						>
							<Download />
						</IconButton>
					</Box>
				</Grid>
				<Grid item xs={12} sm={2}>
					<Box sx={{ display: 'flex', alignItems: 'center'  }}>
						{isLoadingButton && <MoonLoader size={30} />}
					</Box>
				</Grid>
			</Grid>
			{isLoading ? (
				<div>
					<MoonLoader color="#36d7b7" />
				</div>
			) : (
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<StyledTableCell>WO</StyledTableCell>
								<StyledTableCell>Número embarque</StyledTableCell>
								<StyledTableCell>Cliente</StyledTableCell>
								<StyledTableCell>Sonda</StyledTableCell>
								<StyledTableCell>Escopo</StyledTableCell>
								<StyledTableCell>Número de pessoas</StyledTableCell>
								<StyledTableCell>Status do embarque</StyledTableCell>
								<StyledTableCell>Data embarque (PLAN)</StyledTableCell>
								<StyledTableCell>Data desembarque (PLAN)</StyledTableCell>
								<StyledTableCell>Data embarque (PROG)</StyledTableCell>
								<StyledTableCell>Data desembarque (PROG)</StyledTableCell>
								<StyledTableCell>Data embarque (REAL)</StyledTableCell>
								<StyledTableCell>Data desembarque (REAL)</StyledTableCell>
								{!hideEditBoardingButton && <StyledTableCell></StyledTableCell>}
								{!hideDeleteBoardingButton && (
									<StyledTableCell></StyledTableCell>
								)}
							</TableRow>
						</TableHead>
						<TableBody>
							{filteredBoarding
								.sort((a, b) => {
									if (a.project.project_number !== b.project.project_number) {
										return a.project.project_number - b.project.project_number;
									}
									return a.boarding_number - b.boarding_number;
								})
								.map(row => (
									<StyledTableRow key={row.id}>
										<TableCell>{row.project.project_number}</TableCell>
										<TableCell>{row.boarding_number}</TableCell>
										<TableCell>{row.project.client.client_name}</TableCell>
										<TableCell>{row.project.platform.platform_name}</TableCell>
										<TableCell>
											{row.project.project_scope_ids.length > 1
												? `${
														row.project.project_scope_ids[0]?.project_scope
															?.sow ?? ''
												  }...`
												: row.project.project_scope_ids[0]?.project_scope
														?.sow ?? ''}
										</TableCell>
										<TableCell>{row.number_of_workers}</TableCell>
										<TableCell>{row.boarding_status.description}</TableCell>
										<TableCell style={{ whiteSpace: 'nowrap' }}>
											{formatDateToBrazilian(row.planned_embarkation_date)}
										</TableCell>
										<TableCell>
											{formatDateToBrazilian(row.planned_disembarkation_date)}
										</TableCell>
										<TableCell style={{ whiteSpace: 'nowrap' }}>
											{formatDateToBrazilian(row.scheduled_embarkation_date)}
										</TableCell>
										<TableCell>
											{formatDateToBrazilian(row.scheduled_disembarkation_date)}
										</TableCell>
										<TableCell style={{ whiteSpace: 'nowrap' }}>
											{formatDateToBrazilian(row.actual_embarkation_date)}
										</TableCell>
										<TableCell>
											{formatDateToBrazilian(row.actual_disembarkation_date)}
										</TableCell>
										{!hideEditBoardingButton && (
											<TableCell>
												<IconButton
													aria-label="edit"
													onClick={() => {
														navigate(`/edit-planning/${row.id}`);
													}}
													style={{
														padding: '0px', // Reduzindo o padding para diminuir o espaço
													}}
												>
													<EditIcon />
												</IconButton>
											</TableCell>
										)}
										{!hideDeleteBoardingButton && (
											<TableCell>
												<IconButton
													aria-label="delete"
													onClick={() => {
														handleDelete(row.id);
													}}
													style={{
														padding: '0px', // Reduzindo o padding para diminuir o espaço
													}}
												>
													<DeleteForeverIcon />
												</IconButton>
											</TableCell>
										)}
									</StyledTableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</Grid>
	);
}

export default ProjectPlanningPage;
