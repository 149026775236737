import React, { useEffect, useState } from 'react';
import {
	Box,
	Modal,
	Typography,
	IconButton,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MoonLoader } from 'react-spinners';
import {
	apiGetBoardingsByFilters,
	apiGetWorkerBoardingByWorkerId,
	apiUpdateOrAddWorkerBoarding,
	IBoardingBackend,
	IWorkerBackEnd,
	IWorkerBoardingBackEnd,
} from '../../services/apiService';
import axios from 'axios';
import { toast } from 'react-toastify';
import { formatDate } from '../../pages/OperationalProjectionPage/utils';
import { green } from '@mui/material/colors';

function formatDateString(dateString: string): string {
	if (!dateString) return '';

	const [year, month, day] = dateString.split('-');
	return `${day}-${month}-${year}`;
}

function getMinEmbarkationAndMaxDisembarkationDates(
	boardings: IBoardingBackend[]
): {
	minScheduledEmbarkationDate: string | null;
	maxScheduledDisembarkationDate: string | null;
} {
	if (boardings.length === 0) {
		return {
			minScheduledEmbarkationDate: null,
			maxScheduledDisembarkationDate: null,
		};
	}

	let minScheduledEmbarkationDate = boardings[0].scheduled_embarkation_date;
	let maxScheduledDisembarkationDate =
		boardings[0].scheduled_disembarkation_date;

	for (const boarding of boardings) {
		if (
			new Date(boarding.scheduled_embarkation_date) <
			new Date(minScheduledEmbarkationDate)
		) {
			minScheduledEmbarkationDate = boarding.scheduled_embarkation_date;
		}
		if (
			new Date(boarding.scheduled_disembarkation_date) >
			new Date(maxScheduledDisembarkationDate)
		) {
			maxScheduledDisembarkationDate = boarding.scheduled_disembarkation_date;
		}
	}

	return { minScheduledEmbarkationDate, maxScheduledDisembarkationDate };
}

interface IBoardingTableWorkerScheduled extends IBoardingBackend {
	scheduleDisable: boolean;
	schedule: boolean;
	woScheduled: number | null;
	boardingNumber: number | null;
}

function getBoardingTableWorkerScheduled(
	boardings: IBoardingBackend[],
	workerBoardings: IWorkerBoardingBackEnd[]
): IBoardingTableWorkerScheduled[] {
	console.log('ENTREI');

	return boardings.map(boarding => {
		let scheduleDisable = false;
		let schedule = false;
		let woScheduled: number | null = null;
		let boardingNumber: number | null = null;
		console.log('ENTREI 2');
		console.log('workerBoardings 2', workerBoardings);

		for (const workerBoarding of workerBoardings) {
			console.log('ENTREI 3');
			console.log('boarding nnumber', boarding.boarding_number);

			// if (workerBoarding.boarding_id === boarding.id) {
			const startDate = workerBoarding.scheduled_start_date
				? new Date(workerBoarding.scheduled_start_date)
				: null;
			const endDate = workerBoarding.scheduled_end_date
				? new Date(workerBoarding.scheduled_end_date)
				: null;
			const embarkDate = new Date(boarding.scheduled_embarkation_date);
			const disembarkDate = new Date(boarding.scheduled_disembarkation_date);

			console.log(
				'expressao: ',
				(startDate && startDate >= embarkDate && startDate <= disembarkDate) ||
					(endDate && endDate >= embarkDate && endDate <= disembarkDate)
			);

			if (
				(startDate && startDate >= embarkDate && startDate <= disembarkDate) ||
				(endDate && endDate >= embarkDate && endDate <= disembarkDate)
			) {
				scheduleDisable = true;
				schedule = false;
				woScheduled = workerBoarding.boarding.project.project_number;
				boardingNumber = workerBoarding.boarding.boarding_number;
				break;
			}
		}

		return {
			...boarding,
			scheduleDisable,
			schedule,
			woScheduled,
			boardingNumber,
		};
	});
}

interface IProps {
	open: boolean;
	onClose: () => void;
	projectNumber: number | undefined;
	worker: IWorkerBackEnd | null;
	workers: IWorkerBackEnd[];
	setWorkers: (workers: IWorkerBackEnd[]) => void;
}

function WorkerScheduleMultiBoardings({
	open,
	onClose,
	projectNumber,
	worker,
	workers,
	setWorkers,
}: IProps) {
	const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
	const [boardings, setBoardings] = useState<IBoardingBackend[]>([]);
	const [workerBoardings, setWorkerBoardings] = useState<
		IWorkerBoardingBackEnd[]
	>([]);
	const [boardingTableWorkerScheduled, setBoardingTableWorkerScheduled] =
		useState<IBoardingTableWorkerScheduled[]>([]);

	const fetchData = async () => {
		try {
			const boardings = await apiGetBoardingsByFilters(
				'',
				'',
				'',
				projectNumber ?? '',
				'',
				''
			);
			setBoardings(
				boardings.sort((a, b) => a.boarding_number - b.boarding_number)
			);

			const result = getMinEmbarkationAndMaxDisembarkationDates(boardings);
			const minScheduledEmbarkationDate = result.minScheduledEmbarkationDate;
			const maxScheduledDisembarkationDate =
				result.maxScheduledDisembarkationDate;

			try {
				const workerBoardings = await apiGetWorkerBoardingByWorkerId(
					worker!.id,
					minScheduledEmbarkationDate,
					maxScheduledDisembarkationDate
				);
				setWorkerBoardings(workerBoardings);
				console.log('🚀  workerBoardings:', workerBoardings);
				setBoardingTableWorkerScheduled(
					getBoardingTableWorkerScheduled(boardings, workerBoardings)
				);
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response?.status !== 404) {
						toast.error('ERROR: falha ao carregar trabalhador programado.');
					} else {
						setBoardingTableWorkerScheduled(
							getBoardingTableWorkerScheduled(boardings, workerBoardings)
						);
					}
				}
			}

			console.log(
				'🚀  boardingTableWorkerScheduled:',
				boardingTableWorkerScheduled
			);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				if (error.response?.status !== 404) {
					toast.error('ERROR: falha ao carregar dados de embarque.');
				} else {
					toast.warning('WARNING: nenhum embarque encontrado.');
				}
			} else {
				toast.error('ERROR: falha ao carregar dados.');
			}
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, [projectNumber]);
	// }, []);

	const onAddClickWorkers = async () => {
		setIsLoadingButton(true);

		if (
			boardingTableWorkerScheduled.every(
				boarding => boarding.schedule === false
			)
		) {
			toast.warning('WARNING: selecione os embarques.');
		} else {
			try {
				for (const boarding of boardingTableWorkerScheduled) {
					if (boarding.schedule) {
						const updateWorker = {
							boardindId: boarding!.id,
							workerId: worker!.id,
							scheduledStartDate: boarding?.scheduled_embarkation_date,
							scheduledEndDate: boarding?.scheduled_disembarkation_date,
						};

						await apiUpdateOrAddWorkerBoarding(updateWorker);
					}
				}
				await fetchData();
				setWorkers(workers.filter(worker => worker.id !== worker!.id));
				console.log('🚀  workers:', workers);
				console.log(
					'🚀  worker especifco:',
					workers.filter(worker => worker.id === worker!.id)
				);

				toast.success('SUCCESS: programação efetuada.');

				// const filteredWorkers = workers.filter(worker => worker.id !== workerId);
				// setWorkers(filteredWorkers);
				// setAddedWorker(!addedWorker);
			} catch (error) {
				toast.error('ERROR: falha ao fazer programação.');
			}
		}
		setIsLoadingButton(false);
	};

	return (
		<Modal open={open} onClose={onClose} aria-labelledby="modal-title">
			{isLoading ? (
				<div>
					<MoonLoader color="#36d7b7" />
				</div>
			) : (
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 800,
						bgcolor: 'background.paper',
						boxShadow: 24,
						p: 4,
						borderRadius: 2,
						maxHeight: '80vh',
						overflowY: 'auto',
					}}
				>
					<Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
						<Typography id="modal-title" variant="h6">
							<strong>WO {boardings[0].project.project_number}: </strong>
							{worker!.worker_name}
						</Typography>
						<Box>
							<IconButton onClick={onClose}>
								<CloseIcon />
							</IconButton>
						</Box>
					</Box>
					<Box sx={{ marginBottom: 2 }}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								marginTop: '23px',
							}}
						>
							<Button
								variant="contained"
								sx={{ marginRight: 1 }}
								onClick={() => {
									// Verifica se todos os checkboxes selecionáveis já estão marcados
									const allSelected = boardingTableWorkerScheduled.every(
										row => row.schedule || row.scheduleDisable
									);

									// Atualiza o estado para marcar ou desmarcar todos
									const updatedTable = boardingTableWorkerScheduled.map(
										row => ({
											...row,
											schedule: row.scheduleDisable
												? row.schedule
												: !allSelected, // Altera apenas os que podem ser selecionados
										})
									);
									setBoardingTableWorkerScheduled(updatedTable);
								}}
								disabled={isLoadingButton}
							>
								Selecionar todos
							</Button>

							<Button
								variant="contained"
								sx={{
									backgroundColor: green[500],
									'&:hover': {
										backgroundColor: green[700],
									},
									color: 'white',
									minWidth: '115px',
									marginRight: 1,
								}}
								onClick={() => onAddClickWorkers()}
								disabled={isLoadingButton}
							>
								<strong>Adicionar</strong>
							</Button>
							{isLoadingButton && <MoonLoader size={30} />}
						</div>
					</Box>

					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										<strong>Número Embarque</strong>
									</TableCell>
									<TableCell>
										<strong>Data embarque (PROG)</strong>
									</TableCell>
									<TableCell>
										<strong>Data desembarque (PROG)</strong>
									</TableCell>
									<TableCell></TableCell>
									<TableCell>
										<strong>WO Programada</strong>
									</TableCell>
									<TableCell>
										<strong>Número Embarque</strong>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{boardingTableWorkerScheduled.map((row, index) => (
									<TableRow
										key={index}
										sx={{
											backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white', // Cinza leve para linhas pares
										}}
									>
										<TableCell>{row.boarding_number}</TableCell>
										<TableCell>
											{formatDateString(row.scheduled_embarkation_date)}
										</TableCell>
										<TableCell>
											{formatDateString(row.scheduled_disembarkation_date)}
										</TableCell>
										<TableCell>
											<input
												type="checkbox"
												checked={row.schedule}
												onChange={() => {
													const updatedTable = [
														...boardingTableWorkerScheduled,
													];
													updatedTable[index].schedule =
														!updatedTable[index].schedule;
													setBoardingTableWorkerScheduled(updatedTable);
												}}
												disabled={row.scheduleDisable}
											/>
										</TableCell>
										<TableCell>{row.woScheduled ?? ''}</TableCell>
										<TableCell>{row.boardingNumber ?? ''}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			)}
		</Modal>
	);
}

export default WorkerScheduleMultiBoardings;
